import VueBootstrap4Table from 'vue-bootstrap4-table'

export default {
    components: {
        VueBootstrap4Table
    },
    props: {
        //name for the table
        tableName: { type: String, default: ''},
        //data for the table
        header: {type: Array, default: [], required: true},
        items: {type: Array, default: [], required: true},
        total: {type: Number, default: 0, required: true},
        //label for add more button
        addMoreLabel: { type: String, default: 'Add'},
        //show or hide the add more button
        showAddMore: { type: Boolean, default: false},
        showKnownproviderbtn: { type: Boolean, default: false},
        showSearchBtn: { type: Boolean, default: false},
        showSearch: { type: Boolean, default: false},
        //hide global search
        showGlobalSearch: { type: Boolean, default: false},
    },
    data() {
        return {
            showSearch_: false,
            globalSearch: '',
            theTableKey: 0,
            config: {
                card_mode: false,
                highlight_row_hover_color: '#efefef',
                global_search: {
                    visibility: false,
                    showClearButton: false
                },
                show_refresh_button: false,
                show_reset_button: false,

                pagination: true,
                pagination_info: true,
                num_of_visibile_pagination_buttons: 5,
                per_page: 50,
                per_page_options: [10, 20, 30, 40, 50],
                server_mode: true,
                multi_column_sort: true,
                rows_selectable: true,
                selected_rows_info: false,
            },
            queryParams: {
                per_page: 50,
                page: 1,
            },
            classes: {
                table: 'table-hover'
            }
        }
    },
    mounted(){
        this.showSearch_ = this.showSearch
    },
    watch: {
        queryParams: {
            deep: true,
            handler(val) {
                this.$emit('update:searchQuery', {
                    page: val.page,
                    size: val.per_page,
                    sort: val.sort,
                    search: this.globalSearch
                })
            }
        },
        globalSearch(){
            this.$emit('update:searchQuery', {
                page: this.queryParams.page,
                size: this.queryParams.per_page,
                sort: this.queryParams.sort,
                search: this.globalSearch
            })
        }
    },
    computed: {
        slotNameList() {
            var arr = []
            this.header.forEach(el => {
                if(el.name) {
                    arr.push(el.name.replace('.', '_'))
                }
            })
            return arr
        }
    },
    methods: {
        rowClick(payload) {
            this.$emit('rowClick', payload.selected_item)
        },
        fetchDelay() {
            if (this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.timer = setTimeout(() => {
                if (this.queryParams.page == 1){
                    this.requestFetchData()
                } else {
                    this.theTableKey += 1
                }
            }, 500)
        },
        onChangeQuery(queryParams) {
            this.queryParams = queryParams
            this.requestFetchData()
        },
        requestFetchData() {
            //error on server mode ( when not in page 1 and make filter, it will emit twice the request) => set timeout
            if (this.timer2) {
                clearTimeout(this.timer2)
                this.timer2 = null
            }
            this.timer2 = setTimeout(() => {
                this.$emit('requestFetchData')
            }, 5)
        },
        addMoreClick() {
            this.$emit('addMoreClick')
        },
        addknownProviderClick() {
            this.$emit('addknownProviderClick')
        }
    }
}