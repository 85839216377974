import VueBootstrap4Table from 'vue-bootstrap4-table'
import _ from 'lodash'
import draggable from 'vuedraggable'
export default {
    components: {
        VueBootstrap4Table,
        draggable
    },
    props: {
        //name for the table
        tableName: { type: String, default: '' },
        //data for the table
        header: { type: Array, default: [], required: true },
        defaultHeader: { type: Array, default: ()=>[], required: false },
        items: { type: Array, default: [], required: true },
        total: { type: Number, default: 0, required: true },
        //label for add more button
        addMoreLabel: { type: String, default: 'Add' },
        providerLabel: { type: String, default: 'Providers' },
        btnproviderLabel: { type: String, default: 'Providers' },
        //show or hide the add more button
        showAddMore: { type: Boolean, default: false },
        showSearchBtn: { type: Boolean, default: false },
        showSearch: { type: Boolean, default: false },
        //hide global search
        showGlobalSearch: { type: Boolean, default: false },
        showD: { type: Boolean, default: false },
        showProvider: { type: Boolean, default: false },
        showCombineReport: { type: Boolean, default: false },
        showErReport: { type: Boolean, default: false },
        showRecallReport: { type: Boolean, default: false },
        showEventSummaryReport: { type: Boolean, default: false },
        showSupplierSalesRepReport: { type: Boolean, default: false },
        showSuplierLocationReport: { type: Boolean, default: false },
        showProviderOrgReport: { type: Boolean, default: false },
        showSupplier: { type: Boolean, default: false },
        showV: { type: Boolean, default: false },
        showI: { type: Boolean, default: false },
        showNC: { type: Boolean, default: false },
        showE: { type: Boolean, default: false },
        showKnownproviderbtn: { type: Boolean, default: false },
        showAutoNotifybtn: { type: Boolean, default: false },
        autonotifychecked: { type: Boolean, default: false },
        customizationEnabled: { type: Boolean, default: false },
        showUserAccountDownloadReportBtn: { type: Boolean, default: false },
    },
    data() {
        return {
            perPage: 50,
            perPageEmit: 50,
            currentPage: 1,
            per_page: [10, 20, 30, 40, 50],
            showSearch_: false,
            globalSearch: '',
            theTableKey: 0,
            autonotifyvalue: false,
            config: {
                card_mode: false,
                highlight_row_hover_color: '#efefef',
                global_search: {
                    visibility: false,
                    showClearButton: false
                },
                show_refresh_button: false,
                show_reset_button: false,

                pagination: false,
                pagination_info: false,
                num_of_visibile_pagination_buttons: 5,
                per_page: 50,
                per_page_options: [10, 20, 30, 40, 50],
                server_mode: true,
                multi_column_sort: false,
                rows_selectable: true,
                selected_rows_info: false,
            },
            queryParams: {
                per_page: 50,
                page: 1,
            },
            classes: {
                table: 'table-hover'
            },
            headers: [],
            dataHeader: [],
            isDragging: false,
            delayedDragging: false,
            defaultHeaders: []
        }
    },
    created() {
        this.headers = _.cloneDeep(this.header)
        if (this.customizationEnabled) {
            let header = this.headers.filter(x => x.isVisible)
            if (header.length === 1 && header[0].label === '') {
                this.dataHeader = []
            } else {
                this.dataHeader = _.cloneDeep(this.header.filter(x => x.isVisible))
            }
        } else{
            this.dataHeader =_.cloneDeep(this.header)
        }
    },
    mounted() {
        this.showSearch_ = this.showSearch
        this.autonotifyvalue = this.autonotifychecked
    },
    watch: {
        isDragging(newValue) {
            if (newValue) {
                this.delayedDragging = true
                return
            }
            this.$nextTick(() => {
                this.delayedDragging = false
            })
            this.setColumns()
        },
        perPageEmit(val) {
            this.$emit('update:searchQuery', {
                pageNumber: this.currentPage,
                pageSize: val,
                sort: this.queryParams.sort,
                search: this.globalSearch
            })
            this.requestFetchData()
        },
        currentPage(val) {
            this.$emit('update:searchQuery', {
                pageNumber: val,
                pageSize: this.perPageEmit,
                sort: this.queryParams.sort,
                search: this.globalSearch
            })
            this.requestFetchData()
        },
        autonotifychecked(val) {
            this.autonotifyvalue = val
        },
        queryParams: {
            deep: true,
            handler(val) {
                this.$emit('update:searchQuery', {
                    pageNumber: this.currentPage,
                    pageSize: this.perPageEmit,
                    sort: val.sort,
                    search: this.globalSearch
                })
            }
        },
        globalSearch(val) {
            this.$emit('update:searchQuery', {
                pageNumber: this.currentPage,
                pageSize: this.perPageEmit,
                sort: this.queryParams.sort,
                search: val
            })
        }
    },
    computed: {
        dragOptions() {
            return {
                animation: 0,
                group: 'description',
                ghostClass: 'ghost',
            }
        },
        slotNameList() {
            var arr = []
            this.dataHeader.forEach(el => {
                if (el.name) {
                    arr.push(el.name.replace('.', '_'))
                }
            })
            return arr
        }
    },
    methods: {
        remove() {

            this.globalSearch= null
            this.fetchDelay()
        },
        resetToDefault() {
            this.header = _.cloneDeep(this.defaultHeader)
            this.headers = _.cloneDeep(this.defaultHeader)
            this.dataHeader = _.cloneDeep(this.defaultHeader.filter(x => x.isVisible))
            this.$emit('defaultHeaders')
        },
        btnknownProviderClick(){
            this.$emit('btnknownProviderClick')
        },
        setColumns() {
            let heder = this.headers.filter(x => x.isVisible)
            if (heder.length === 1 && heder[0].label === '') {
                this.dataHeader = []
            } else {
                this.dataHeader = this.headers.filter(x => x.isVisible)
            }
            this.$emit('updateHeaders', this.headers)
        },
        onMove({ relatedContext, draggedContext }) {
            const relatedElement = relatedContext.element
            const draggedElement = draggedContext.element
            return (
                (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
            )
        },
        selectPerPage(perPage) {
            this.perPage = perPage
            this.perPageEmit = perPage
        },
        checkautonotifychecked(e) {
            if (e == false) {
                this.autonotifyvalue = false
                this.$emit('autonotifybtnChecked', false)
            }
            else {
                this.autonotifyvalue = true
                this.$emit('autonotifybtnChecked', true)
            }
        },
        rowClick(payload) {
            this.$emit('rowClick', payload.selected_item)
        },
        rowUnClick(payload) {
            this.$emit('rowClick', payload.unselected_item)
        },
        fetchDelay() {
            if (this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.timer = setTimeout(() => {
                if (this.queryParams.page == 1) {
                    this.requestFetchData()
                } else {
                    this.theTableKey += 1
                }
            }, 500)
        },
        onChangeQuery(queryParams) {
            this.queryParams = queryParams
            this.requestFetchData()
        },
        requestFetchData() {
            //error on server mode ( when not in page 1 and make filter, it will emit twice the request) => set timeout
            if (this.timer2) {
                clearTimeout(this.timer2)
                this.timer2 = null
            }
            this.timer2 = setTimeout(() => {
                this.$emit('requestFetchData')
            }, 5)
        },
        addMoreClick() {
            this.$emit('addMoreClick')
        },
        addknownProviderClick() {
            this.$emit('addknownProviderClick')
        },
        addDClick() {
            this.$emit('addDClick')
        },
        addProviderClick() {
            this.$emit('addProviderClick')
        },
        addexportsupplierlocationReport() {
            this.$emit('addexportsupplierlocationReport')
        },
        adddownloadsupplierlocationReport() {
            this.$emit('adddownloadsupplierlocationReport')
        },
        addDelegateReportClick() {
            this.$emit('addDelegateReportClick')
        },
        addDelegateCSVReportClick() {
            this.$emit('addDelegateCSVReportClick')
        },

        btnErCSVClick() {
            this.$emit('btnErCSVClick')
        },
        btnErXLSXClick() {
            this.$emit('btnErXLSXClick')
        },
        btnRecallCSVClick() {
            this.$emit('btnRecallCSVClick')
        },

        btnRecallXLSXClick() {
            this.$emit('btnRecallXLSXClick')
        },
        btnEventSummaryCSVClick() {
            this.$emit('btnEventSummaryCSVClick')
        },
        btnEventSummaryXLSXClick() {
            this.$emit('btnEventSummaryXLSXClick')
        },
        downloadsuppliersalesrepReport() {
            this.$emit('downloadsuppliersalesrepReport')
        },
        btnProviderAccountCSVClick() {
            this.$emit('btnProviderAccountCSVClick')
        },
        btnProviderAccountXLSXClick() {
            this.$emit('btnProviderAccountXLSXClick')
        },
        addVerifyClick() {
            this.$emit('addVerifyClick')
        },
        addEmailedClick() {
            this.$emit('addEmailedClick')
        },
        addInvalidClick() {
            this.$emit('addInvalidClick')
        },
        addCoveredClick() {
            this.$emit('addCoveredClick')
        },
        DisableClick() {
            this.$emit('DisableClick')
        },
        btnUserAccountReportXLSXClick() {
            this.$emit('btnUserAccountReportXLSXClick')
        }
    }
}