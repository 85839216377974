<template>
  <div v-if="!this.params.data.invalid" class="ag-labeled ag-label-align-right ag-checkbox ag-input-field">
    <noti-form-select
      v-model="value"
      :options="listItems"
      label=""
      :trackBy="trackBy"
      :labelBy="labelBy"
      @update="onChange"
      :hideCrossIcon=false
      class="ag-grid-drop-down"
      style="margin-top:16px"
    />
  </div>
</template>
<script>
export default {
    data() {
        return {
            listItems: [],
            value: '',
            trackBy: '',
            labelBy: '',
        }
    },
    beforeMount() {
        this.listItems= this.params.listItems
        this.trackBy= this.params.trackBy
        this.labelBy= this.params.labelBy
        this.value= this.params.value
    },
    methods: {
        onChange(event) {
            if (this.params.column.colDef.HeaderChangeCallback) {
                this.params.column.colDef.HeaderChangeCallback(this.params.data, this.value)
            }
        },
    },
}
</script>
