<template>
  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="12" cy="18.2996" rx="10" ry="3.70044" :fill="hovered ? hoverColor : fillColor" />
    <rect x="6.2619" y="1.5" width="11.5556" height="18.4278" rx="1.5" :fill="hovered ? hoverColor : fillColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M11.5814 3.9503C11.5814 3.42545 11.156 3 10.6311 3H8.44832C7.92347 3 7.49802 3.42545 7.49802 3.9503V6.13309C7.49802 6.65794 7.92347 7.08339 8.44832 7.08339H10.6311C11.156 7.08339 11.5814 6.65794 11.5814 6.13309V3.9503ZM8.44832 3.9503H10.6311V6.13309H8.44832V3.9503Z"
          fill="white" />
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M11.5814 8.9503C11.5814 8.42545 11.156 8 10.6311 8H8.44832C7.92347 8 7.49802 8.42545 7.49802 8.9503V11.1331C7.49802 11.6579 7.92347 12.0834 8.44832 12.0834H10.6311C11.156 12.0834 11.5814 11.6579 11.5814 11.1331V8.9503ZM8.44832 8.9503H10.6311V11.1331H8.44832V8.9503Z"
          fill="white" />
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M16.5814 3.9503C16.5814 3.42545 16.156 3 15.6311 3H13.4483C12.9235 3 12.498 3.42545 12.498 3.9503V6.13309C12.498 6.65794 12.9235 7.08339 13.4483 7.08339H15.6311C16.156 7.08339 16.5814 6.65794 16.5814 6.13309V3.9503ZM13.4483 3.9503H15.6311V6.13309H13.4483V3.9503Z"
          fill="white" />
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M16.5814 8.9503C16.5814 8.42545 16.156 8 15.6311 8H13.4483C12.9235 8 12.498 8.42545 12.498 8.9503V11.1331C12.498 11.6579 12.9235 12.0834 13.4483 12.0834H15.6311C16.156 12.0834 16.5814 11.6579 16.5814 11.1331V8.9503ZM13.4483 8.9503H15.6311V11.1331H13.4483V8.9503Z"
          fill="white" />
    <path
      d="M7.58746 14.0288V19.9823H8.53292V14.0288H11.5875V19.9823H12.5329V14.0288H15.5465V19.9823H16.4919V14.0288C16.4919 13.5066 16.0687 13.0834 15.5465 13.0834H8.53292C8.01074 13.0834 7.58746 13.5066 7.58746 14.0288Z"
      fill="white" />
    <rect x="6.2619" y="1.5" width="11.5556" height="18.4278" rx="1.5" stroke="white" />
  </svg>

</template>
<script>
export default {
    props: {
        hovered: {
            type: Boolean,
            default: false
        },
        hoverColor: {
            type: String,
            default: '#009efb' // default hover color
        },
        fillColor: {
            type: String,
            default: '#8a8589' // default fill color
        }
    }
}
</script>