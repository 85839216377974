<template>
  <div id="app">
    <component :is="layout"/>
    <core-loading />
    <core-notify ref="notify" />
    <core-toast ref="toast" />
    <core-confirm ref="confirm" />
    <div v-if="appNewVersionAvailable && !prodAppNewVersionAvailable"  class="updatemodal-overlay">
      <div class="updatemodal">
        <h4>New version of NotiSphere app is available</h4>
        <div class="mt-2">Please click on the refresh button to get the latest version.</div>
        <b-button style="float: right;" variant="primary"  @click="reloadPage" size="sm" class="mt-2 "> <i class="fas fa-refresh" aria-hidden="true"></i> Refresh </b-button>
      </div>
    </div>
    <div v-if="prodAppNewVersionAvailable"  class="updatemodal-overlay">
      <div class="prodUpdatemodal">
        <h4>Notisphere has new address!!</h4>
        <div class="mt-2">The domain <span class="mt-2" v-if="env == 'QA'">“qa.notisphererecalls.com” </span><span class="mt-2" v-if="env == 'DEMO'">“demo.notisphererecalls.com” </span><span class="mt-2" v-if="env == 'PROD'">“notisphererecalls.com” </span><span class="mt-2">has been migrated to </span><span class="mt-2" v-if="env == 'QA'">“qa.notispherealerts.com”.</span><span class="mt-2" v-if="env == 'PROD'">“notispherealerts.com”.</span><span class="mt-2" v-if="env == 'DEMO'">“demo.notispherealerts.com”.</span> Please click on “Redirect” button to redirect to new domain.</div>
        <b-button style="float: right;" variant="primary"  @click="redirectProdPage" size="sm" class="mt-2 "> <i class="fas fa-refresh" aria-hidden="true"></i> Redirect </b-button>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.updatemodal-overlay {
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    z-index: 9999;
     background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;

  }
  .updatemodal-blurr {
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    z-index: 0;
     background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;

  }
  .updatemodal {
    position: absolute;
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  .accessmodal {
    position: absolute;
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 35%;
  }
  .accessmodalsup {
    position: absolute;
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 17%;
  }
  @media only screen and (max-width: 778px) {
    .prodUpdatemodal {
    position: absolute;
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 26em !important;
  }
  }
  @media only screen and (min-width: 991px) {
    .prodUpdatemodal {
    position: absolute;
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 26em;
  }
  }
  .prodUpdatemodal {
    position: absolute;
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 25%;
  }
td.DescriptioncolClass
{  width: 44% !important;
}
td.responsecolClass
{width: 15%!important;
}
td.modifiedcolClass
{width: 16%!important;
}
#editoractionView.ql-container.ql-snow {
    border:0px !important
}
.editorvueaction .ql-editor {
    min-height: 45px !important;
     max-height: 85px !important;
}
.contenteditorvue .ql-editor {
  width: 78%;
  margin: auto;
}
.editorvueaction .ql-toolbar{
   border: 1px solid #eee !important;
}
#editoractionAdd
{
 border: 1px solid #fff !important;
}
.textwrap{
overflow-wrap: anywhere !important;
}
strong
{
    font-weight: bold !important;
}
.vue-tags-input
{
  max-width: 100% !important;
}
p {
    margin-top: 0;
    margin-bottom: 0px !important;
}
.dis{
  .ti-input {
    border: 0px #eaeaea !important;
    display: flex;
    padding: 6px 12px;
    flex-wrap: wrap;
    border-radius: 6px;
    min-height: 38px;
    margin-top: -2px;
    .ti-tags{
      .ti-new-tag-input-wrapper
      {
        display: none;
      }
    }
  }
}
.erresponse-enable
{
  .ti-input {
    display: flex;
    padding: 6px 12px;
    flex-wrap: wrap;
    border-radius: 6px;
    min-height: 38px;
  }
}
.erresponse-enable:hover {
  .ti-input {
    border-color: #009efb !important;
  }
}
.enable-additional
{
  .ti-input {
    border: none !important;
    display: flex;
    padding: 6px 12px;
    flex-wrap: wrap;
    border-radius: 6px;
    min-height: 38px;
    margin-top: 3px;
  }
  .ti-tags
  {
    margin-top: -2px;
  }
}
.ViewCCemails
    {
      background-color: #e9ecef !important;
      border-radius: 6px !important;
      .ti-input
      {
        .ti-tags
        {
          .ti-new-tag-input-wrapper
      {
        display: none !important;
      }
        }
      }
    }
.ti-new-tag-input
{
  color:rgba(0,0,0,0.8)
}
.vue-border
{
  .ti-input
  {
    border-color: #eaeaea !important;
  }
}
.ti-input {
  border: 1px normal #eaeaea;
  display: flex;
  padding: 6px 12px;
  flex-wrap: wrap;
  border-radius: 6px;
  min-height: 38px;
  color: red;
}
.ti-tag
{
  background-color: #009efb !important;
}
.ti-selected-item
{
  background-color: #009efb !important;
}
.ti-item > div
{
  padding: 6px 12px !important;
}
.ti-autocomplete
{
  border: 1px solid #eaeaea !important;
  border-radius: 6px;
}
.ti-autocomplete > ul
{
  max-height: 200px;
  overflow: auto;
}
::-ms-reveal {
    display: none;
}
div.ql-tooltip.ql-editing {
  left:10px !important
}
.ti-new-tag-input-wrapper
{
  font-size: 14px !important;
}
.custom_floating_label
{
   background-color: white;
    padding-left: 3px;
    padding-right: 4px;
    margin-left: -3px;
}
.vdp-datepicker__clear-button
{
    transform: translateY(20%) !important;
    font-size: 21px !important;
    font-weight: 500 !important;
    position: absolute !important;
    right: 10px !important;
    top:-3px !important;
}

// Ag-grid
.ag-theme-alpine { font-family: Rubik, sans-serif !important; }
.ag-theme-alpine .ag-row-loading { visibility: hidden; }
.ag-root-wrapper { border: none !important; }
.ag-theme-alpine .ag-header-viewport { background-color: #fff; }
// .ag-theme-alpine .ag-filter-select { display: none; }
.ag-theme-alpine .ag-column-select-header { display: none; }
.ag-theme-alpine .ag-row { background-color: #fff; text-align: left; line-height: 1.5rem !important; }
.ag-theme-alpine .ag-header-cell-label { font-weight: bold; color: black; }
.ag-theme-alpine .ag-header-cell { background-color: #fff; padding-left: 10px; padding-right: 10px; }
.ag-header-cell:hover { background: rgb(239, 239, 239) !important; }
div.ag-row-hover:not(.ag-row-group) div{ background: rgb(239, 239, 239) !important; cursor: pointer; }
.ag-theme-alpine .ag-cell {
  border-color: rgba(0, 0, 0, 0) !important;
  font-size: 0.875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  line-height: 21px;
}
.ag-cell-wrapper{
  display: inline-block !important;
  width: 100%;
}
.ag-row {
    z-index: 0 !important;
}

.ag-row.ag-row-focus {
    z-index: 1 !important;
}
.ag-center-cols-clipper{
  min-height: 270px !important;
}
.ag-theme-alpine .ag-floating-filter-body input {
    height:49px
}

.ag-overlay {
  top: 55px !important;
}

.ag-selection-checkbox {
  justify-content: center !important;
}
</style>

<script>
if(window.navigator && navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations()
        .then(function(registrations) {
            for(let registration of registrations) {
                registration.unregister()
            }
        })
}
import authServices from '../src/services/auth'
export default {
    data() {
        return {
            prodAppNewVersionAvailable: false,
            env: '',
            CommunicationType: '',
            SupplierName: '',
            ProviderName: '',
            AccountAdministratorFirstName: '',
            AccountAdministratorLastName: '',
            AccountAdministratorEmailAddress: '',
        }
    },
    computed: {
        layout() {
            return this.$route.meta.layout || 'layout-default'
        },
        appNewVersionAvailable(){
            return this.$store?.state?.isNewAppVersionAvailable
        },
        permissionDenied(){
            return this.$store?.state?.isPermissionDenied
        },
        permissionErrorMessage(){
            return this.$store?.state?.permissionErrorMessage
        }
    },
    created(){
        if(window.location.origin.includes('https://notisphererecalls.com') || window.location.origin.includes('https://www.notisphererecalls.com'))
        {
            if(window.location.pathname.includes('webform'))
            {
                window.location.replace('https://notispherealerts.com' + window.location.pathname + window.location.search)
            }
            else{
                this.env = 'PROD'
                this.prodAppNewVersionAvailable = true
            }
        }
        else if(window.location.origin.includes('https://demo.notisphererecalls.com'))
        {
            if(window.location.pathname.includes('webform'))
            {
                window.location.replace('https://demo.notispherealerts.com' + window.location.pathname + window.location.search)
            }
            else{
                this.env = 'DEMO'
                this.prodAppNewVersionAvailable = true
            }
        }
        else if(window.location.origin.includes('https://qa.notisphererecalls.com'))
        {
            if(window.location.pathname.includes('webform'))
            {
                window.location.replace('https://qa.notispherealerts.com' + window.location.pathname + window.location.search)
            }
            else{
                this.env = 'QA'
                this.prodAppNewVersionAvailable = true
            }
        }
    },
    mounted() {
        window.vm = this.$refs.notify || null
        window.vmToast = this.$refs.toast || null
    },
    watch: {
        '$route' (to, from) {
            document.title = to.meta.title || 'NotiSphere'
        }
    },
    methods: {
        reloadPage(){
            this.$store.state.isNewAppVersionAvailable= false
            localStorage.setItem('app-version', this.$store.state.appVersionNumber)
            window.location.reload(true) // For new version, simply reload on any get
        },
        hideModal(){
            this.$store.state.isPermissionDenied= false
            window.location.reload(true)
        },
        redirectProdPage(){
            if(this.env == 'PROD')
            {
                window.location.replace('https://notispherealerts.com' + window.location.pathname + window.location.search)
            }
            else if(this.env == 'DEMO')
            {
                window.location.replace('https://demo.notispherealerts.com' + window.location.pathname + window.location.search)
            }
            else if(this.env == 'QA')
            {
                window.location.replace('https://qa.notispherealerts.com' + window.location.pathname + window.location.search)
            }
        },
    }
}
</script>
