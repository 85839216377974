export default {
    name: 'SearchAutocomplete',
    props: {
        items: {
            type: Array,
            required: false,
            default: () => [],
        },
        isAsync: {
            type: Boolean,
            required: false,
            default: false,
        },
        value: {},
        label: { type: String, default: ''},
        placeholder: { type: String, default: ''},
        rules: { type: String, default: ''},
        validatorName: { type: String, default: ''},
        disabled: { type: Boolean, default: false},
        hideDetails: { type: Boolean, default: false},
    },
    data() {
        return {
            results: [],
            isOpen: false,
            arrowCounter: -1,
            floatLabel: false,
            isFocus: false,
            isLoading: false,
            selectedvalue: '',
            fsWidth: 0,

        }
    },
    computed: {
        disabledValidate() {
            return !this.rules || this.rules == '' ? true : false
        },
        lblWidth: {
            get() {
                if (this.floatLabel)
                    return this.fsWidth + 'px'
                return 0
            }
        }
    },
    watch: {
        items: function (value, oldValue) {
            if (this.isAsync) {
                this.results = value
                this.isOpen = true
                this.isLoading = false
            }
        },
        selectedvalue(val){
            this.checkFloatLabel(val)
        }
    },
    mounted() {
        this.selectedvalue=this.value
        this.checkFloatLabel(this.selectedvalue)
        document.addEventListener('click', this.handleClickOutside)
    },
    destroyed() {
        document.removeEventListener('click', this.handleClickOutside)
    },
    methods: {
        fieldFocus() {
            this.addFloatLabel()
            this.addFocus()
        },
        fieldBlur() {
            if(!this.selectedvalue){
                this.removeFloatLabel()
            }
            this.removeFocus()
        },
        checkFloatLabel(val) {
            if(!val && !this.isFocus){
                this.removeFloatLabel()
            } else {
                this.addFloatLabel()
            }
        },
        addFloatLabel() {
            this.$refs.wrapper.$el.classList.add('float-label')
            setTimeout(()=> {
                if(this.$refs.lbl != undefined)
                {
                    this.fsWidth = this.$refs.lbl.clientWidth
                }

            }, 250)
            this.floatLabel = true
        },
        removeFloatLabel() {
            this.$refs.wrapper.$el.classList.remove('float-label')
            this.floatLabel = false
        },
        addFocus() {
            this.$refs.wrapper.$el.classList.add('focus')
            this.isFocus = true
        },
        removeFocus() {
            this.$refs.wrapper.$el.classList.remove('focus')
            this.isFocus = false
        },
        clickLabel() {
            this.$refs.theInput.focus()
        },
        getClassIndicate(validationContext){
            if(validationContext.validated || validationContext.dirty){
                var IsVal = this._getValidationState(validationContext) ? 'valid' : 'fail'
                return IsVal
            } return ''
        },
        onArrowDown() {
            if (this.arrowCounter < this.results.length) {
                this.arrowCounter = this.arrowCounter + 1
            }
        },
        onArrowUp() {
            if (this.arrowCounter > 0) {
                this.arrowCounter = this.arrowCounter - 1
            }
        },
        onEnter() {
            let val = this.results[this.arrowCounter]
            if(val != undefined)
            {
                this.selectedvalue = this.results[this.arrowCounter]
                this.arrowCounter = -1
                this.isOpen = false
            }
            this.$emit('input',  this.selectedvalue)
        },
        handleClickOutside(event) {
            if (!this.$el.contains(event.target)) {
                this.isOpen = false
                this.arrowCounter = -1
            }
        },
        setResult(result) {
            this.selectedvalue = result
            this.isOpen = false
            this.$emit('input', result)
        },
        filterResults() {
            this.results = this.items.filter(item => item.toLowerCase().indexOf(this.selectedvalue.toLowerCase()) > -1)
        },
        onChange() {
            this.$emit('input', this.selectedvalue)
            if (this.isAsync) {
                this.isLoading = true
            } else {
                this.filterResults()
                this.isOpen = true
            }
        }
    },
}