import {http, param} from '../../utils/http'
import conf from '../../config'

export const API_ROOT = `${conf.API_URL}`

const LocationPreProcessorService = {
    not_createPreprocessorjob (formData) {
        return http.post(`${API_ROOT}/notisphere/locationPreProcessor/upload-locationpreprocessor-file`,formData ).then(resp => {
            return resp
        })
    },
    findLocationPreprocessor(payload) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'search', val: payload.search ? encodeURIComponent(payload.search.trim()) : '' },
            { query: 'status', val: payload.status },
            { query: 'suppliername', val: payload.supplierName ? encodeURIComponent(payload.supplierName) : ''},
        ]
        return http.get(`${API_ROOT}/notisphere/locationPreProcessor${param(arr)}`).then(resp => {
            return resp
        })
    },
    getLocationPreProcessorJobById(id) {
        return http.get(`${API_ROOT}/notisphere/locationPreProcessor/${id}`).then(resp => {
            return resp
        })
    },
    getDocumentById(docId) {
        return http.get(`${API_ROOT}/notisphere/locationPreProcessor/document/${docId}`).then(resp => {
            return resp
        })
    },
    deleteLocationPreProcessorJob (PreProcessorId) {
        return http.put(`${API_ROOT}/notisphere/locationPreProcessor/${PreProcessorId}`).then(resp => {
            return resp
        })
    },
    getLocationPreProcessorSuppliers() {
        return http.get(`${API_ROOT}/notisphere/locationPreProcessor/locationPreProcessorSuppliers`).then(resp => {
            return resp
        })
    },
}

export default LocationPreProcessorService
