import _ from 'lodash'
import authService from '../services/auth'
import state from './state'
import { APP_MUTATIONS, AUTH_MUTATIONS, LANG_MUTATIONS } from './mutations'
import conf from '../config'
import { forceRedirect } from '../router'
import appStore from '../store'
export const APP_ACTIONS = {
    SET_PREVIOUS_ROUTE: 'SET_PREVIOUS_ROUTE',
    LASTED_ROUTE_NOT_AUTH: 'LASTED_ROUTE_NOT_AUTH'
}

export const AUTH_ACTIONS = {
    LOGOUT: 'LOGOUT',
    LOGIN: 'LOGIN',
    GET_USER_PROFILE: 'GET_USER_PROFILE',
    GET_ORGANIZATION_INFO: 'GET_ORGANIZATION_INFO',
    REFRESHTOKEN: 'REFRESHTOKEN',
}

export const LANG_ACTIONS = {
    SET_LANG: 'SET_LANG'
}

const appActions = {
    [APP_ACTIONS.SET_PREVIOUS_ROUTE]: (context, data) => {
        if (data) {
            if (!context.state.authRoutes.includes(data.path) && !data.meta.auth) {
                context.commit(APP_MUTATIONS.SET_PREVIOUS_ROUTE, data)
            }
        }
    },
    [APP_ACTIONS.LASTED_ROUTE_NOT_AUTH]: (context, data) => {
        if (data) {
            if (!context.state.authRoutes.includes(data.path) && !data.meta.auth) {
                context.commit(APP_MUTATIONS.LASTED_ROUTE_NOT_AUTH, data)
            }
            if (!context.state.authRoutes.includes(data.path)) {
                context.commit(APP_MUTATIONS.LASTED_ROUTE_NOT_AUTH, data)
            }
        }
    }
}

const authActions = {
    [AUTH_ACTIONS.LOGIN]: (context, payload = {}) => {
        const {
            grant_type,
            email,
            password,
            verification_code,
            phone_number,
            code_req,
            refresh_token,
            login_as,
            login_as_org_id,
            auth_code,
            nounce,
        } = _.isObject(payload) ? payload : {}
        context.commit(APP_MUTATIONS.AUTH_REQUEST)
        return authService.login(conf.CLIENT_ID, grant_type, email, password,verification_code,phone_number,code_req, refresh_token, login_as, login_as_org_id, auth_code, nounce).then(resp => {
            if (resp.error) {
                context.commit(AUTH_MUTATIONS.AUTH_ERROR)
            } else {
                if (resp.data.d.login_as_required) {
                    let obj = resp.data.d
                    context.commit(AUTH_MUTATIONS.SELECT_TYPE_LOGIN, obj)
                } else {
                    if (resp.data.d.Challenge) {
                        return resp
                    } else {
                        let token = resp.data.d
                        context.commit(AUTH_MUTATIONS.AUTH_SUCCESS, token)
                        context.dispatch(AUTH_ACTIONS.GET_USER_PROFILE)
                        forceRedirect()
                        return resp
                    }

                }
            }
            return resp
        }).catch(() => {
            context.commit(AUTH_MUTATIONS.AUTH_ERROR)
        })
    },

    [AUTH_ACTIONS.REFRESHTOKEN]: (context, payload = {}) => {
        const {
            refresh_token,
            access_token
        } = _.isObject(payload) ? payload : {}
        context.commit(APP_MUTATIONS.AUTH_REQUEST)
        return authService.RefreshToken(refresh_token,access_token).then(resp => {
            if (resp.error) {
                context.commit(AUTH_MUTATIONS.AUTH_ERROR)
            } else {
                let token = resp.data.d
                context.commit(AUTH_MUTATIONS.REFRESHTOKEN_SUCCESS, token)
                context.dispatch(AUTH_ACTIONS.GET_USER_PROFILE)
            }
            return resp
        }).catch(() => {
            context.commit(AUTH_MUTATIONS.AUTH_ERROR)
        })
    },

    [AUTH_ACTIONS.GET_USER_PROFILE]: (context) => {
        if (appStore.state.user === '' && appStore.state.token) {
            return authService.getUserProfile().then(resp => {
                if (!resp.error) {
                    context.commit(AUTH_MUTATIONS.UPDATE_USER_PROFILE, resp.data.d)
                    context.dispatch(AUTH_ACTIONS.GET_ORGANIZATION_INFO)
                }
                return resp
            })
        }
    },
    [AUTH_ACTIONS.GET_ORGANIZATION_INFO]: (context) => {
        if (appStore.state.token) {
            return authService.getOrganizationInfo().then(resp => {
                if (!resp.error) {
                    context.commit(AUTH_MUTATIONS.UPDATE_ORGANIZATION_INFO, resp.data.d)
                }
                return resp
            })
        }
    },
    [AUTH_ACTIONS.LOGOUT]: (context, currentRoute) => {
        if (currentRoute && currentRoute.meta && currentRoute.meta.auth) {
            context.commit(APP_MUTATIONS.LASTED_ROUTE_NOT_AUTH, currentRoute)
        }
        context.commit(AUTH_MUTATIONS.AUTH_ERROR)
        context.state.isNewAppVersionAvailable=false
        return authService.logout().then(resp => {

        })
    },
}

const langActions = {
    [LANG_ACTIONS.SET_LANG]: (context, payload) => {
        context.commit(LANG_MUTATIONS.SET_LANG, payload)
        localStorage.i18n_locale = payload
    }
}

export default {
    ...appActions,
    ...authActions,
    ...langActions
}
