import recallServices from '../../../../services/provider/recall'
import FileViewModel from '../FileViewModel'
import delegateService from '../../../../services/provider/delegates'
import { ALLOWEDEXTENSIONS,ALLOWEDIMAGEEXTENSIONS} from '../../../../misc/commons'
import communicationNoticeService from '../../../../services/provider/communicationNotices'
export default {
    props: {
        type: { type: String, default: '' },
        value: {
            type: Object,
            default: {
                valueAcknowledge: null,
                valueText: null,
                valueNumeric: null,
                valueYesNo: null,
                valueCheckbox: null,
                valueImage: null,
                valueDocument: null,
                valueMultiChoice: [],
            },
        },
        actionValues: [],
        recallsite: [],
        communicationId: { type: String, default: null },
        providerCommunicationId: { type: String, default: '' },
        communicationType: {type: String, default: ''},
        recallActionId: { type: Number, default: null },
        editMode: { type: Boolean, default: false },
        isSupplier: { type: Boolean, default: false },
        isProvider: { type: Boolean, default: false },
        isDelegate: { type: Boolean, default: false },
        isRecallNotice: { type: Boolean, default: false },
        email: { type: String, default: null },
        communicationAffectedCustomerId: { type: Number, default: null },
        org_Id: { type: Number, default: null },
        delegateId: { type: Number, default: null },
        disabled: { type: Boolean, default: false },
        disabledBtn: { type: Boolean, default: false },
    },
    data() {
        return {
            extensionAllowed: ALLOWEDEXTENSIONS,
            imageExtensionAllowed: ALLOWEDIMAGEEXTENSIONS
        }
    },
    components: {
        FileViewModel,
    },
    computed: {
        _value: {
            get() {
                let numValue = 0
                switch (this.type) {
                case 'Acknowledge':
                    return this.value && this.value.valueAcknowledge === true
                        ? true
                        : this.value && this.value.valueAcknowledge === false
                            ? false
                            : null
                case 'Text':
                    return this.value && this.value.valueText
                        ? this.value.valueText
                        : null
                case 'MultipleChoice':
                    return this.value && this.value.length > 0 ? this.value : null
                case 'Numeric':
                    numValue = this.value && Number.isInteger(this.value.valueNumeric) ? this.value.valueNumeric : null
                    if (numValue != null && numValue == 0) {
                        return String(numValue)
                    } else {
                        return this.value && this.value.valueNumeric
                            ? this.value.valueNumeric
                            : null
                    }
                case 'YesNo':
                    return this.value && this.value.valueYesNo === true
                        ? true
                        : this.value && this.value.valueYesNo === false
                            ? false
                            : null
                case 'Checkbox':
                    return this.value && this.value.valueCheckbox === true
                        ? true
                        : this.value && this.value.valueCheckbox === false
                            ? false
                            : null
                }
                return null
            },
            set(val) {
                switch (this.type) {
                case 'Text':
                    this.$emit('input', { valueText: val })
                    break
                case 'Numeric':
                    this.$emit('input', { valueNumeric: val })
                    break
                case 'YesNo':
                    this.$emit('input', { valueYesNo: val })
                    break
                case 'Checkbox':
                    this.$emit('input', { valueCheckbox: val })
                    break
                }
            },
        },
    },
    methods: {
        isChange() {
            this.$emit('isChange', true)
        },
        calculateDisabledState() {
            return this.disabled || this.disabledBtn
        },
        async imageInput() {
            let uploadedFiles = this.$refs.file.files
            let extension=this.$refs.file.files[0]?.name?.split('.')?.pop()
            if (!this.imageExtensionAllowed.includes(extension.toLowerCase())) {
                return   this._showToast('The document types should be either of .png, .jpg', { variant: 'danger' })
            }
            for (let i = 0; i < uploadedFiles.length; i++) {
                const file = uploadedFiles[i]
                await this.uploadRecallActionFile(file)
            }
            this.getRecallActionFilesAsync()
        },
        async fileInput() {
            let uploadedFiles = this.$refs.file.files
            let extension=this.$refs.file.files[0]?.name?.split('.')?.pop()
            if (!(this.extensionAllowed.includes(extension.toLowerCase()) || this.imageExtensionAllowed.includes(extension.toLowerCase()))) {
                return   this._showToast('The document types should be either of .pdf, .docx, .png, .jpg .xlsx, .mp4', { variant: 'danger' })
            }
            for (let i = 0; i < uploadedFiles.length; i++) {
                const file = uploadedFiles[i]
                await this.uploadRecallActionFile(file)
            }
            this.getRecallActionFilesAsync()
        },
        uploadRecallActionFile(file) {
            return new Promise((resolve, reject) => {
                let formData = new FormData()
                formData.append('actionType', this.type)
                formData.append('file', file)
                if (this.isDelegate) {
                    delegateService
                        .uploadRecallActionFile(
                            formData,
                            this.communicationId,
                            this.communicationType,
                            this.recallActionId,
                            this.org_Id,
                            this.delegateId
                        )
                        .then((resp) => {
                            if (resp.error) {
                                reject()
                            } else {
                                resolve()
                            }
                        })
                        .finally(() => {
                            reject()
                        })
                }
                else if (this.isRecallNotice) {

                    communicationNoticeService.uploadCommunicationActionFile(formData, this.org_Id, this.communicationId, this.communicationType, this.recallActionId, this.providerCommunicationId, this.email)
                        .then((resp) => {
                            if (resp.error) {
                                reject()
                            } else {
                                resolve()
                            }
                        })
                        .finally(() => {
                            reject()
                        })
                }
                else {
                    recallServices
                        .uploadRecallActionFile(
                            formData,
                            this.communicationId,
                            this.communicationType,
                            this.recallActionId,
                            this.providerCommunicationId,
                            this.email
                        )
                        .then((resp) => {
                            if (resp.error) {
                                reject()
                            } else {
                                resolve()
                            }
                        })
                        .finally(() => {
                            reject()
                        })
                }
            })
        },
        getRecallActionFilesAsync() {
            if (this.isDelegate) {
                delegateService
                    .getRecallActionFilesAsync(
                        this.communicationId,
                        this.recallActionId,
                        this.type,
                        this.org_Id,
                        this.delegateId
                    )
                    .then((resp) => {
                        if (!resp.error) {
                            if (this.type == 'Image') {
                                this.$emit('input', { valueImage: resp.data.d })
                            }
                            if (this.type == 'Document') {
                                this.$emit('input', { valueDocument: resp.data.d })
                            }
                        }
                    })
            }
            else if (this.isRecallNotice) {
                communicationNoticeService.communicationActionFiles(this.org_Id, this.communicationId, this.recallActionId, this.type, this.providerCommunicationId, this.communicationAffectedCustomerId
                ).then((resp) => {
                    if (!resp.error) {
                        if (this.type == 'Image') {
                            this.$emit('input', { valueImage: resp.data.d })
                        }
                        if (this.type == 'Document') {
                            this.$emit('input', { valueDocument: resp.data.d })
                        }
                    }
                })
            }
            else {
                recallServices
                    .getRecallActionFilesAsync(
                        this.communicationId,
                        this.recallActionId,
                        this.type,
                        this.providerCommunicationId,
                        this.email
                    )
                    .then((resp) => {
                        if (!resp.error) {
                            if (this.type == 'Image') {
                                this.$emit('input', { valueImage: resp.data.d })
                            }
                            if (this.type == 'Document') {
                                this.$emit('input', { valueDocument: resp.data.d })
                            }
                        }
                    })
            }
        },
        deleteRecallActionFileAsync(fileId) {
            if (this.isDelegate) {
                delegateService
                    .deleteRecallActionFileAsync(
                        this.communicationId,
                        this.communicationType,
                        this.recallActionId,
                        fileId,
                        this.org_Id,
                        this.delegateId
                    )
                    .then((resp) => {
                        if (!resp.error) {
                            this._showToast('Successfully Deleted!', { variant: 'success' })
                            this.getRecallActionFilesAsync()
                        }
                    })
                this.$refs.file.value = ''
            }
            else if (this.isRecallNotice) {
                communicationNoticeService.deleteCommunicationActionFile(this.org_Id, this.communicationId, this.communicationType, this.recallActionId, fileId)
                    .then((resp) => {
                        if (!resp.error) {
                            this._showToast('Successfully Deleted!', { variant: 'success' })
                            this.getRecallActionFilesAsync()
                        }
                    })
                this.$refs.file.value = ''
            }
            else {
                recallServices
                    .deleteRecallActionFileAsync(
                        this.communicationId,
                        this.communicationType,
                        this.recallActionId,
                        fileId
                    )
                    .then((resp) => {
                        if (!resp.error) {
                            this._showToast('Successfully Deleted!', { variant: 'success' })
                            this.getRecallActionFilesAsync()
                        }
                    })
                this.$refs.file.value = ''
            }
        },
    },
}
