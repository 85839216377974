import http from '../utils/http'
import conf from '../config'

export const API_ROOT = `${conf.API_URL}`

const authService = {
    login (client_id, grant_type, email, password,verification_code,phone_number,code_req, refresh_token, login_as, login_as_org_id,auth_code,nounce) {
        return http.post(`${API_ROOT}/auth/token`, {
            client_id,
            grant_type,
            email,
            password: password,
            verification_code: verification_code,
            phone_number: phone_number,
            code_req: code_req,
            refresh_token: refresh_token,
            login_as: login_as,
            auth_code: auth_code,
            nounce: nounce,
            login_as_org_id: login_as_org_id
        }).then(resp => {
            return resp
        })
    },
    logout () {
        return http.post(`${API_ROOT}/auth/logout`).then(resp => {
            return resp
        })
    },
    healthCheck () {
        return http.get(`${API_ROOT}/health`).then(resp => {
            return resp
        })
    },
    getUserProfile () {
        return http.get(`${API_ROOT}/me`).then(resp => {
            return resp
        })
    },
    GetOrganizationBySelfRegisrationlink (link) {
        return http.get(`${API_ROOT}/auth/organization-bylink?selfregistrationlink=${link}`).then(resp => {
            return resp
        })
    },
    GetUserDataId (email,password,verification_code,resend,session) {
        return http.post(`${API_ROOT}/auth/userdata`,{
            email,
            password,
            verification_code,
            resend,
            session
        }).then(resp => {
            return resp
        })
    },
    async IDPLogout() {
        return http.get(`${API_ROOT}/auth/idp/logout`).then(resp => {
            return resp
        })
    },
    UserLockout (email,password) {
        return http.post(`${API_ROOT}/auth/userlock`,{
            email,
            password
        }).then(resp => {
            return resp
        })
    },
    UserLockoutOTP (email,password) {
        return http.post(`${API_ROOT}/auth/userlockotp`,{
            email,
            password
        }).then(resp => {
            return resp
        })
    },
    UserRestore (email,password) {
        return http.post(`${API_ROOT}/auth/restoreuser`,{
            email,
            password
        }).then(resp => {
            return resp
        })
    },
    GetOrganizationById (organizationId) {
        return http.get(`${API_ROOT}/auth/organization-byId?organizationId=${organizationId}`).then(resp => {
            return resp
        })
    },
    signupUser(payload) {
        return http.post(`${API_ROOT}/auth/SignUpUser`, payload).then(resp => {
            return resp
        })
    },
    updateProfile (payload) {
        return http.put(`${API_ROOT}/me`, payload).then(resp => {
            return resp
        })
    },
    changePassword (payload) {
        return http.put(`${API_ROOT}/me/change-password`, payload).then(resp => {
            return resp
        })
    },
    getOrganizationInfo () {
        return http.get(`${API_ROOT}/me/organization`).then(resp => {
            return resp
        })
    },
    RefreshToken (refresh_token,accesstoken) {
        return http.post(`${API_ROOT}/auth/refreshtoken`, {
            refresh_token: refresh_token,
            access_token: accesstoken
        }).then(resp => {
            return resp
        })
    },
    async getCommunicationDetails(communicationId,providerCommunicationId) {
        return http.get(`${API_ROOT}/auth/getcommunicationdetails/${communicationId}/view/${providerCommunicationId}`).then(resp => {
            return resp
        })
    },
    async requestAccess(communicationId,providerCommunicationId) {
        return http.get(`${API_ROOT}/auth/request/${communicationId}/access/${providerCommunicationId}`).then(resp => {
            return resp
        })
    },
}

export default authService
