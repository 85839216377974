import proCommunicationServices from '../../../../services/provider/recall'
import supCommunicationServices from '../../../../services/supplier/supplier-recall'
import delegateService from '../../../../services/provider/delegates'
import  communicationNoticeService from '../../../../services/provider/communicationNotices'
import {FILEPREVIEW } from '../../../../misc/commons'
export default {
    props: {
        fileId: {type: String, default: null},
        showBtn: {type: Boolean, default: true},
        isSupplier: {type: Boolean, default: false},
        isProvider: {type: Boolean, default: false},
        isPaperResponse: {type: Boolean, default: false},
        showView: {type: Boolean, default: false},
        isDelegate: {type: Boolean, default: false},
        isRecallNotice: {type: Boolean, default: false},
        org_Id: {type: Number, default: null}
    },
    data() {
        return {
            url: require('@/assets/img/notisphere-blue.png'),
            item: null
        }
    },
    computed: {
        fileType(){
            if (this.item.type && this.item.type.split('/')[0] == 'image') {
                return 'image'
            } else
            if (this.item.type && this.item.type.split('/')[0] == 'application' && this.item.type.split('/')[1] == 'pdf') {
                return 'pdf'
            }
            else if (this.item.type && this.item.type.split('/')[0] == 'application' && (this.item.type.split('/')[1] == FILEPREVIEW.SPREADSHEET|| this.item.type.split('/')[1] == 'vnd.ms-excel')) {
                this.item.signed_url_review = FILEPREVIEW.URL + encodeURIComponent(this.item.signed_url_review)
                return 'xlsx'
            }
            else if (this.item.type && this.item.type.split('/')[0] == 'text' && this.item.type.split('/')[1] == 'csv') {
                this.item.signed_url_review = FILEPREVIEW.URL + encodeURIComponent(this.item.signed_url_review)
                return 'csv'
            }
            else if (this.item.type && this.item.type.split('/')[0] == 'text' && this.item.type.split('/')[1] == 'plain') {
                return 'text'
            }
            else if (this.item.type && this.item.type.split('/')[0] == 'application' && (this.item.type.split('/')[1] == 'msword' || this.item.type.split('/')[1] == FILEPREVIEW.DOCUMENT)) {
                this.item.signed_url_review = FILEPREVIEW.URL + encodeURIComponent(this.item.signed_url_review)
                return 'doc'
            }
            else if (this.item.type && this.item.type.split('/')[0] == 'application' && (this.item.type.split('/')[1] == FILEPREVIEW.PPT || this.item.type.split('/')[1] == FILEPREVIEW.DOCUMENT)) {
                this.item.signed_url_review = FILEPREVIEW.URL + encodeURIComponent(this.item.signed_url_review)
                return 'ppt'
            }
            else {
                return null
            }
        }
    },
    methods: {
        forceRerenderView() {
            this.getFileById().then(()=>{
                this.$refs.modal.show()
            })
        },
        download() {

            if (this.item.signed_url) {
                this.openExternalLink(this.item.signed_url)
            } else {
                this._showToast('Nothing to download', {variant: 'danger'})
            }
        },
        async forceRerenderDownload() {
            await this.getFileById()
            this.download()
        },
        getFileById() {
            return new Promise ((resolve, reject) => {
                if (this.isSupplier == true) {
                    supCommunicationServices.getFileById(this.fileId).then(resp => {
                        if (!resp.error) {
                            this.item = resp.data.d
                            resolve()
                        } else {
                            reject()
                        }
                    })
                }
                else if (this.isPaperResponse == true) {
                    supCommunicationServices.getPaperFileById(this.fileId).then(resp => {
                        if (!resp.error) {
                            this.item = resp.data.d
                            resolve()
                        } else {
                            reject()
                        }
                    })
                }
                else if (this.isProvider == true) {
                    proCommunicationServices.getFileById(this.fileId).then(resp => {
                        if (!resp.error) {
                            this.item = resp.data.d
                            resolve()
                        } else {
                            reject()
                        }
                    })
                }
                else if (this.isDelegate == true) {
                    delegateService.getFileById(this.fileId,this.org_Id).then(resp => {
                        if (!resp.error) {
                            this.item = resp.data.d
                            resolve()
                        } else {
                            reject()
                        }
                    })
                } else if (this.isRecallNotice == true) {
                    communicationNoticeService.file(this.org_Id,this.fileId).then(resp => {
                        if (!resp.error) {
                            this.item = resp.data.d
                            resolve()
                        } else {
                            reject()
                        }
                    })
                }
                else {
                    proCommunicationServices.getDelegateFileById(this.fileId).then(resp => {
                        if (!resp.error) {
                            this.item = resp.data.d
                            resolve()
                        } else {
                            reject()
                        }
                    })
                }
            })
        }
    }
}