
import Datepicker from 'vuejs-datepicker'

export default {
    props: {
        value: {},
        disabledDates: {},
        highlighted: {},
        label: { type: String, default: ''},
        clearButton: { type: Boolean, default: false },
        rules: { type: String, default: ''},
        validatorName: { type: String, default: ''},
        disabled: { type: Boolean, default: false},
        hideDetails: { type: Boolean, default: false},
        idName: { type: String, default: ''},
        tabindex: { type: String, default: '0' },
    },
    components: {
        Datepicker
    },
    data(){
        return {
            fsWidth: 0,
            floatLabel: false,
            isFocus: false,
        }
    },
    mounted(){
        this.checkFloatLabel(this._value)
    },
    computed: {
        disabledValidate() {
            return !this.rules || this.rules == '' ? true : false
        },
        _value: {
            get(){
                return this.value
            },
            set(val){
                this.$emit('input', val)
            },
        },
        lblWidth: {
            get() {
                if (this.floatLabel)
                    return this.fsWidth + 'px'
                return 0
            }
        }
    },
    watch: {
        _value(val){
            this.checkFloatLabel(val)
        }
    },
    methods: {
        onUpdate() {
            this.$emit('update')
        },
        onSelect() {
            this.$emit('onSelect')
        },
        clickLabel() {
            if(this.$refs.theInput.isOpen ){
                this.$refs.theInput.close()
            } else{
                this.addFocus()
                setTimeout((ev)=>{
                    this.$refs.theInput.isOpen || this.$refs.theInput.showCalendar(ev)
                }, 50)
            }

        },

        fieldFocus() {
            this.addFloatLabel()
            this.addFocus()
        },
        fieldBlur() {
            if(!this._value){
                this.removeFloatLabel()
            }
            this.removeFocus()
        },
        checkFloatLabel(val) {
            if(!val && !this.isFocus){
                this.removeFloatLabel()
            } else {
                this.addFloatLabel()
            }
        },
        addFloatLabel() {
            var IdTwo = this.$refs.wrapper.$el.children[0].childNodes[1].children[1] == undefined ? this.$refs.wrapper.$el.children[0].childNodes[1].children[0] : this.$refs.wrapper.$el.children[0].childNodes[1].children[1]
            if(this.$refs.wrapper.$el.parentElement.id != 'sup' && IdTwo.id != 'sup1')
            {
                this.$refs.wrapper.$el.classList.add('float-label')
                setTimeout(()=> {
                    this.fsWidth = this.$refs.lbl.clientWidth
                }, 250)
                this.floatLabel = true
            }
        },
        removeFloatLabel() {
            this.$refs.wrapper.$el.classList.remove('float-label')
            this.floatLabel = false
        },
        addFocus() {
            this.$refs.wrapper.$el.classList.add('focus')
            this.isFocus = true
        },
        removeFocus() {
            this.$refs.wrapper.$el.classList.remove('focus')
            this.isFocus = false
        },
    }
}