/**
 * Vuex
 *
 * @library
 *
 * https://vuex.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
// Store functionality
import actions from './actions'
import modules from './modules'
import mutations from './mutations'
import getters from './getters'
import state from './state'


Vue.use(Vuex)

const vuexPersist = new VuexPersistence({
    storage: window.localStorage,
    reducer: (state) => ({CustomizedHeaders: state.CustomizedHeaders,SavedAllFilterConnections: state.SavedAllFilterConnections,user: state.user,organization: state.organization}),
    key: '35FF6F68-0A95-40FB-BB54-895C484E9F38'
})
// Create a new store
const store = new Vuex.Store({
    state,
    actions,
    modules,
    mutations,
    getters,
    plugins: [vuexPersist.plugin]
})

export default store
