const conf = {
    BASE_URL: process.env.VUE_APP_BASE_URL,
    API_URL: process.env.VUE_APP_BASE_API,
    CLIENT_ID: process.env.VUE_APP_CLIENT_ID,
    SITE_KEY: process.env.VUE_APP_SITE_KEY,
    SECRET_KEY: process.env.VUE_APP_SECRET_KEY,
    V2_SITE_KEY: process.env.VUE_APP_SITE_KEY_V2,
    USER_POOL_ID: process.env.VUE_APP_USER_POOL_ID,
    POOL_CLIENT_ID: process.env.VUE_APP_POOL_CLIENT_ID,
    REMEMBER_DEVICE: process.env.VUE_APP_REMEMBER_DEVICE,
    OPEN_FDA_BASE_URL: process.env.VUE_APP_OPEN_FDA_BASE_URL,
}

export default conf

