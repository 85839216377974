<template>
  <svg width="20" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.6907 15.9358L10.3407 18.2896C9.36639 19.2655 7.78865 19.2655 6.8152 18.2898C5.84156 17.3146 5.84156 15.7341 6.815 14.7591L11.5158 10.0505C12.4893 9.07544 14.0672 9.07544 15.0406 10.0505C15.3651 10.3756 15.8913 10.3756 16.2158 10.0505C16.5403 9.72548 16.5403 9.19845 16.2158 8.87341C14.5933 7.24825 11.9632 7.24825 10.3407 8.87341L5.63987 13.5819C4.01736 15.2071 4.01736 17.8415 5.63987 19.4667C7.26217 21.0927 9.89252 21.0927 11.5159 19.4667L13.8659 17.1128C14.1904 16.7878 14.1904 16.2608 13.8659 15.9357C13.5414 15.6107 13.0152 15.6107 12.6907 15.9358Z"
      :fill="hovered ? hoverColor : fillColor" />
    <path
      d="M23.199 1.90354C21.5669 0.280445 18.9205 0.280445 17.2884 1.90354L14.4524 4.72406C14.126 5.04868 14.126 5.57504 14.4524 5.89966C14.7788 6.22429 15.3081 6.22429 15.6345 5.89966L18.4705 3.07914C19.4497 2.1053 21.0377 2.1053 22.0169 3.07914C22.9961 4.05294 22.9961 5.63142 22.0169 6.60522L16.8162 11.7775C15.837 12.7513 14.2499 12.7513 13.2707 11.7775C12.9443 11.4528 12.415 11.4528 12.0886 11.7775C11.7622 12.1021 11.7622 12.6284 12.0886 12.9531C13.7207 14.5762 16.3663 14.5762 17.9983 12.9531L23.199 7.78087C24.831 6.15777 24.831 3.52663 23.199 1.90354Z"
      :fill="hovered ? hoverColor : fillColor" />
    <path
      d="M0.678642 13.0843H7.29657C7.67141 13.0843 7.97521 12.7805 7.97521 12.4056C7.97521 12.0308 7.67141 11.727 7.29657 11.727H0.678642C0.303801 11.727 -4.17233e-07 12.0308 -4.17233e-07 12.4056C-4.17233e-07 12.7805 0.303801 13.0843 0.678642 13.0843Z"
      :fill="hovered ? hoverColor : fillColor" />
    <path
      d="M3.20118 9.10254C2.82634 9.10254 2.52254 9.40634 2.52254 9.78118C2.52254 10.156 2.82634 10.4598 3.20118 10.4598H9.58064C9.95548 10.4598 10.2593 10.156 10.2593 9.78118C10.2593 9.40634 9.95548 9.10254 9.58064 9.10254H3.20118Z"
      :fill="hovered ? hoverColor : fillColor" />
    <path
      d="M15.8709 1.50067C15.496 1.50067 15.1922 1.80447 15.1922 2.17931C15.1922 2.55416 15.496 2.85796 15.8709 2.85796H17.6802C18.055 2.85796 18.3588 2.55416 18.3588 2.17931C18.3588 1.80447 18.055 1.50067 17.6802 1.50067H15.8709Z"
      :fill="hovered ? hoverColor : fillColor" />
    <path
      d="M7.72607 6.11609C7.35123 6.11609 7.04742 6.41989 7.04742 6.79473C7.04742 7.16957 7.35123 7.47337 7.72607 7.47337H9.53538C9.91022 7.47337 10.214 7.16957 10.214 6.79473C10.214 6.41989 9.91022 6.11609 9.53538 6.11609H7.72607Z"
      :fill="hovered ? hoverColor : fillColor" />
    <path
      d="M16.5268 4.07983C16.5268 3.70499 16.223 3.40118 15.8482 3.40118H11.0745C10.6996 3.40118 10.3958 3.70499 10.3958 4.07983C10.3958 4.45467 10.6996 4.75847 11.0745 4.75847H15.8482C16.223 4.75856 16.5268 4.45467 16.5268 4.07983Z"
      :fill="hovered ? hoverColor : fillColor" />
    <path
      d="M5.39458 15.2516C5.39458 14.8767 5.09078 14.5729 4.71594 14.5729H1.39159C1.01675 14.5729 0.712952 14.8767 0.712952 15.2516C0.712952 15.6264 1.01666 15.9302 1.39159 15.9302H4.71594C5.09078 15.9302 5.39458 15.6264 5.39458 15.2516Z"
      :fill="hovered ? hoverColor : fillColor" />
  </svg>

</template>
<script>
export default {
    props: {
        hovered: {
            type: Boolean,
            default: false
        },
        hoverColor: {
            type: String,
            default: '#009efb' // default hover color
        },
        fillColor: {
            type: String,
            default: '#8a8589' // default fill color
        }
    }
}
</script>