<template>
  <svg width="20" height="29" viewBox="0 0 20 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 25.5659C10.8259 24.6375 11.1632 24.2311 13.9503 21.1384C18.5975 15.9817 20 14.4255 20 10.5547C20 5.04069 15.514 0.554688 10 0.554688C4.486 0.554688 0 5.04069 0 10.5547C0 14.4408 1.52622 16.1314 6.05672 21.15C8.78289 24.1699 9.3075 24.7879 10 25.5659ZM10 2.77691C14.2887 2.77691 17.7778 6.26602 17.7778 10.5547C17.7778 13.4885 16.9981 14.4371 12.2996 19.6507C11.6083 20.4178 10.8469 21.2627 9.99983 22.2139C9.1565 21.2675 8.39639 20.4255 7.70622 19.6609C3.27506 14.7524 2.22222 13.5861 2.22222 10.5547C2.22222 6.26602 5.71133 2.77691 10 2.77691V2.77691Z"
      :fill="hovered ? hoverColor : fillColor" />
    <path
      d="M15.5414 9.73672L14.4903 9.61047C14.3829 9.20957 14.2255 8.83081 14.023 8.47938L14.6764 7.64884C14.7763 7.52118 14.765 7.32379 14.6495 7.20884L13.5665 6.12533C13.4524 6.01085 13.2546 5.99861 13.1264 6.09848L12.2945 6.75282C11.9444 6.55026 11.5656 6.39291 11.1657 6.28644L11.0399 5.23544C11.0206 5.07479 10.8722 4.94336 10.7101 4.94336H9.17805C9.01599 4.94336 8.86759 5.07479 8.84875 5.23544L8.72249 6.28644C8.32205 6.39338 7.9428 6.5512 7.59277 6.7533L6.76125 6.09989C6.63405 6.00002 6.43619 6.0118 6.32171 6.12627L5.23815 7.21026C5.12414 7.32426 5.11237 7.52212 5.21271 7.65026L5.86567 8.48126C5.66309 8.83176 5.50574 9.21098 5.3988 9.61188L4.34822 9.73767C4.18758 9.75651 4.05566 9.9049 4.05566 10.0674V11.5994C4.05566 11.7615 4.1871 11.9089 4.34822 11.9287L5.39927 12.055C5.50669 12.4545 5.66357 12.8332 5.86614 13.1837L5.21318 14.0157C5.11378 14.1433 5.12509 14.3407 5.24004 14.4557L6.32171 15.5396C6.43619 15.6536 6.63405 15.6664 6.76125 15.5665L7.59324 14.9117C7.94374 15.1142 8.32252 15.2721 8.72296 15.3785L8.84922 16.4291C8.86853 16.5902 9.01646 16.7211 9.17853 16.7211H10.7106C10.8731 16.7211 11.021 16.5902 11.0399 16.4291L11.1661 15.3785C11.5671 15.2711 11.9458 15.1138 12.2959 14.9117L13.1278 15.5651C13.2555 15.6649 13.4529 15.6532 13.5674 15.5387L14.6505 14.4547C14.7654 14.3407 14.7772 14.1428 14.6769 14.0142L14.023 13.1832C14.226 12.8327 14.3829 12.454 14.4903 12.054L15.5409 11.9278C15.702 11.9085 15.8334 11.7615 15.8334 11.5985V10.0665C15.8339 9.90349 15.7029 9.75604 15.5414 9.73672ZM9.94502 13.7763C8.31875 13.7763 7.00058 12.4582 7.00058 10.832C7.00058 9.2058 8.31875 7.88769 9.94502 7.88769C11.5713 7.88769 12.8895 9.2058 12.8895 10.832C12.8895 12.4582 11.5718 13.7763 9.94502 13.7763Z"
      :fill="hovered ? hoverColor : fillColor" />
    <path d="M4.44434 26.7773H15.5554V28.9996H4.44434V26.7773Z" :fill="hovered ? hoverColor : fillColor" />
  </svg>

</template>
<script>
export default {
    props: {
        hovered: {
            type: Boolean,
            default: false
        },
        hoverColor: {
            type: String,
            default: '#009efb' // default hover color
        },
        fillColor: {
            type: String,
            default: '#8a8589' // default fill color
        }
    }
}
</script>