<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.344"
    height="16"
    viewBox="0 0 21.344 16"
  >
    <g id="recall-settings" transform="translate(0 176)">
      <g id="Group_10842" data-name="Group 10842" transform="translate(0 -176)">
        <g id="Group_10841" data-name="Group 10841">
          <path id="Path_10" data-name="Path 10" d="M13.928,65.029l7.325,5.959a.406.406,0,0,0,.08.052.676.676,0,0,0,.307.074.72.72,0,0,0,.391-.129l7.32-5.956a.444.444,0,0,0-.026-.71A1.768,1.768,0,0,0,28.308,64H14.968a1.766,1.766,0,0,0-1.014.32.444.444,0,0,0-.026.71Z" transform="translate(-13.19 -64)" :fill="hovered ? hoverColor : fillColor"/>
          <path id="Path_11" data-name="Path 11" d="M11.737,119.163l1.258-1.258a1.312,1.312,0,0,1,1.107-.38.46.46,0,0,0,.408-.168,1.327,1.327,0,0,1,1.053-.516h.889a.444.444,0,0,0,.445-.445v-2.38a.444.444,0,0,0-.725-.345l-6.823,5.552-.077.052a1.553,1.553,0,0,1-1.722-.051L.725,113.671a.444.444,0,0,0-.725.345v8.161a1.781,1.781,0,0,0,1.779,1.779h8.449a.444.444,0,0,0,.445-.445v-1.779a1.326,1.326,0,0,1,.516-1.053.444.444,0,0,0,.168-.407A1.323,1.323,0,0,1,11.737,119.163Z" transform="translate(0 -111.505)" :fill="hovered ? hoverColor : fillColor"/>
          <path id="Path_12" data-name="Path 12" d="M286.777,216.9l-.852-.213.452-.753a.445.445,0,0,0-.067-.543l-1.258-1.258a.444.444,0,0,0-.544-.067l-.752.452-.213-.852a.445.445,0,0,0-.432-.337h-1.779a.445.445,0,0,0-.432.337l-.213.852-.752-.452a.446.446,0,0,0-.544.067l-1.258,1.258a.445.445,0,0,0-.067.543l.452.753-.852.213a.444.444,0,0,0-.337.431v1.779a.444.444,0,0,0,.337.431l.852.213-.452.753a.445.445,0,0,0,.067.543l1.258,1.258a.446.446,0,0,0,.544.067l.752-.452.213.852a.445.445,0,0,0,.432.337h1.779a.445.445,0,0,0,.432-.337l.213-.852.752.452a.446.446,0,0,0,.544-.067l1.258-1.258a.445.445,0,0,0,.067-.543l-.452-.753.852-.213a.444.444,0,0,0,.337-.431v-1.779A.444.444,0,0,0,286.777,216.9Zm-4.554,3.544a2.223,2.223,0,1,1,2.223-2.223A2.226,2.226,0,0,1,282.222,220.447Z" transform="translate(-265.77 -207.115)" :fill="hovered ? hoverColor : fillColor"/>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
    props: {
        hovered: {
            type: Boolean,
            default: false
        },
        hoverColor: {
            type: String,
            default: '#009efb' // default hover color
        },
        fillColor: {
            type: String,
            default: '#8a8589' // default fill color
        }
    }
}
</script>