import route from '../misc/route'
import { USER_TYPE } from '../misc/commons'

const META_AUTH_REQUIRED = {
    auth: true
}
// for lazy loading
const Login = () => import('../views/share/login')
const Signup = () => import('../views/share/signup')
const SuccessPage = () => import('../views/share/success-page')
const SignUpSuccess = () => import('../views/share/signup-success')
const ForgotPassword = () => import('../views/share/forgot-password')
const NotFound = () => import('../views/share/404')
const Forbidden = () => import('../views/share/404')
const Account = () => import('../views/share/account')
const EditAccount = () => import('../views/share/account/edit-account')
const User = () => import('../views/share/user')
const Role = () => import('../views/share/role')
const Faq = () => import('../views/share/faq')
const Contact = () => import('../views/share/contact')
const UserGuide = () => import('../views/share/user-guide')
//callback-url-handler
const AccountVerification = () => import('../views/callback-url-handler/AccountVerification')
const ResetPassword = () => import('../views/callback-url-handler/ResetPassword')
const DelegateWebform = () => import('../views/share/delegate-webform')
const SupplierDelegateWebform = () => import('../views/share/delegate-webform')
const recallNoticeWebform = () => import('../views/provider/communication-notices/recall')
const recallNoticeConfirmation =() => import('../views/share/centralized-notice-webform/confirmation')
const disruptionNoticeWebform = () => import('../views/provider/communication-notices/disruption')
const salesRepNoticeWebform = () => import('../views/share/sales-rep-notices')
const disruptionNoticeConfirmation =() => import('../views/share/centralized-notice-webform/confirmation')
//for notisphere
const noti_Dashboard = () => import('../views/notisphere/dashboard')
const noti_Provider = () => import('../views/notisphere/provider')
const noti_Recall = () => import('../views/notisphere/recall')
const noti_er_Responses=()=>import('../views/notisphere/er-response')
const noti_RecallView = () => import('../views/notisphere/recall-view')
const noti_Supplier = () => import('../views/notisphere/supplier')
const noti_FAQ = () => import('../views/notisphere/faq')
const noti_EmailTemplate = () => import('../views/share/email')
const noti_ContentManagement = () => import('../views/share/content-management')
const noti_Location = () => import('../views/notisphere/location')
const noti_Known_Provider = () => import('../views/notisphere/known-providers')
const noti_LocationVerification = () => import('../views/notisphere/location-verification')
const noti_QuickLinks = () => import('../views/notisphere/quick-links')
const noti_LookupValues = () => import('../views/notisphere/lookup-values')
const noti_ProductField = () => import('../views/notisphere/product-field')
const noti_UserGuide = () => import('../views/notisphere/manage-user-guide')
const noti_SupplierList = () => import('../views/notisphere/manage-supplier-list')
const noti_Key_Metrics=()=> import('../views/notisphere/key-metrics')
const noti_Location_preProcessor = () => import('../views/notisphere/location-pre-processor')
const noti_Location_preProcessorView = () => import('../views/notisphere/location-pre-processor/Locationpreprocessor-View')
const noti_OpenFda = () => import('../views/notisphere/open-fda')
//for provider
const prov_Dashboard = () => import('../views/provider/dashboard')
const prov_Recall = () => import('../views/provider/recall')
const er_Responses=()=>import('../views/provider/er-response')
const prov_RecallView = () => import('../views/provider/recall-view')
const prov_DisruptionView = () => import('../views/provider/disruption-view')
const prov_ProductDisruption_AccessDenied_ViewItem = () => import('../views/provider/access-denied-disruption')

const prov_ProdDisruption = () => import('../views/provider/disruption/list')
const prov_ProductDisruption_ItemDetail = () => import('../views/provider/disruption/add-disruption')
const prov_ProductDisruption_ViewItem  = () => import('../views/provider/disruption/view-disruption')

const prov_LocationVerification = () => import('../views/provider/location-verification')
const prov_Site = () => import('../views/provider/sites')
const prov_Organization = () => import('../views/provider/organization')
const prov_Recall_ItemDetail = () => import('../views/provider/recall/item-detail')
const prov_Internal_Actions=()=> import('../views/share/internal-actions')
const prov_Distribution_List=()=> import('../views/share/distribution-list')
const prov_SupplierContacts_List=()=> import('../views/provider/supplier-contacts')
const prov_EmailTemplate = () => import('../views/share/email')
const prov_EmailTemplate_Edit = () => import('../views/share/email/EmailModal')
//for supplier
const supp_Dashboard = () => import('../views/supplier/dashboard')
const supp_Recall = () => import('../views/supplier/recall/list')
const supp_ProdDisruption = () => import('../views/supplier/disruption/list')
const supp_Recall_ItemDetail = () => import('../views/supplier/recall/item-detail')
const supp_ProductDisruption_ItemDetail = () => import('../views/supplier/disruption/add-disruption')
const supp_ProductDisruption_ViewItem  = () => import('../views/supplier/disruption/view-disruption')
const supp_Recall_ViewItem = () => import('../views/supplier/recall-view')
const supp_Organization = () => import('../views/supplier/organization')
const supp_Provider = () => import('../views/supplier/provider')
const supp_Location = () => import('../views/supplier/location')
const supp_SalesRep = () => import('../views/supplier/sales-rep')
const supp_LocationFiles = () => import('../views/supplier/location-file')
const supp_Internal_Actions = () => import('../views/share/internal-actions')
const supp_Internal_Actions_Pd = () => import('../views/share/internal-actions')
const noti_EmailTemplate_Edit = () => import('../views/share/email/EmailModal')
const supp_Distribution_List=()=> import('../views/share/distribution-list')
const supp_Distribution_Lists_Pd=()=> import('../views/share/distribution-list')

const noti_LocationPreProcessor_ItemDetail = () => import('../views/notisphere/location-pre-processor/Location-pre-processorItemdetail')
export const paths={
    //callback-url-handler
    accountVerification: route.register( '/verify-email', 'AccountVerification', AccountVerification, { auth: false, layout: 'layout-simple' } ),
    resetPassword: route.register( '/reset-password', 'ResetPassword', ResetPassword, { auth: false, layout: 'layout-simple' } ),
    //shared
    login: route.register( '/login', 'Login', Login, { auth: false, layout: 'layout-simple' } ),
    forgotPassword: route.register( '/forgot-password', 'ForgotPassword', ForgotPassword, { auth: false, layout: 'layout-simple' } ),
    successPage: route.register('/success', 'SuccessPage', SuccessPage, { auth: false, layout: 'layout-simple' }),
    SignUpSuccess: route.register( '/success-signup', 'SignUpSuccess', SignUpSuccess, { auth: false, layout: 'layout-simple' } ),
    notFound: route.register( '/not-found', 'NotFound', NotFound, { ...META_AUTH_REQUIRED, breadCrumb: '404' ,layout: 'layout-simple'} ),
    notFounds: route.register( '/not-founds', 'NotFound', NotFound, { auth: false, breadCrumb: '404',layout: 'layout-simple' } ),
    forbidden: route.register( '/forbidden', 'Forbidden', Forbidden, { ...META_AUTH_REQUIRED, breadCrumb: '404' } ),
    account: route.register( '/account', 'Account', Account, { ...META_AUTH_REQUIRED, breadCrumb: 'Profile' } ),
    editAccount: route.register( '/account/edit', 'Edit Account', EditAccount, { ...META_AUTH_REQUIRED, breadCrumb: 'Edit' } ),
    faq: route.register( '/faqs', 'Faq', Faq, { ...META_AUTH_REQUIRED, breadCrumb: 'FAQs' } ),
    contact: route.register( '/contact-us', 'Contact', Contact, { ...META_AUTH_REQUIRED, breadCrumb: 'Contact Us' } ),
    userGuide: route.register( '/user-guide', 'UserGuide', UserGuide, { ...META_AUTH_REQUIRED, breadCrumb: 'User Guide' } ),
    signup: route.register( '/registration/:name', 'Signup', Signup, { auth: false, layout: 'layout-simple' } ),
    //for notisphere
    noti_Dashboard: route.register( '/notisphere/dashboard', 'noti_Dashboard', noti_Dashboard, { ...META_AUTH_REQUIRED, breadCrumb: 'Dashboard', userTypes: [USER_TYPE.NOTI] } ),
    noti_Key_Metrics: route.register( '/notisphere/key-metrics', 'noti_Key_Metrics', noti_Key_Metrics, { ...META_AUTH_REQUIRED, breadCrumb: 'Key Metrics', userTypes: [USER_TYPE.NOTI], permissions: ['Notisphere_ViewKeyMetrics']  } ),
    noti_Provider: route.register( '/notisphere/providers', 'noti_Provider', noti_Provider, { ...META_AUTH_REQUIRED, breadCrumb: 'Providers', userTypes: [USER_TYPE.NOTI], permissions: ['Notisphere_ManageProviders'] } ),
    noti_er_Responses: route.register( '/notisphere/er-response', 'noti_er_Responses', noti_er_Responses, { ...META_AUTH_REQUIRED, breadCrumb: 'eR Responses', userTypes: [USER_TYPE.NOTI], permissions: ['NotiSphere_ResponderRole'] } ),
    noti_Recall: route.register( '/notisphere/recalls', 'noti_Recall', noti_Recall, { ...META_AUTH_REQUIRED, breadCrumb: 'Recalls', userTypes: [USER_TYPE.NOTI], permissions: ['Notisphere_ManageRecalls'] } ),
    noti_RecallView: route.register( '/notisphere/recalls/view/:id', 'noti_RecallView', noti_RecallView, { ...META_AUTH_REQUIRED, breadCrumb: 'View', userTypes: [USER_TYPE.NOTI], permissions: ['Notisphere_ManageRecalls'] } ),
    noti_Role: route.register( '/notisphere/roles', 'noti_Role', Role, { ...META_AUTH_REQUIRED, breadCrumb: 'Roles', userTypes: [USER_TYPE.NOTI], permissions: ['Notisphere_ManageRoles'] } ),
    noti_Supplier: route.register( '/notisphere/suppliers', 'noti_Supplier', noti_Supplier, { ...META_AUTH_REQUIRED, breadCrumb: 'Suppliers', userTypes: [USER_TYPE.NOTI], permissions: ['Notisphere_ManageSuppliers'] } ),
    noti_User: route.register( '/notisphere/users', 'noti_User', User, { ...META_AUTH_REQUIRED, breadCrumb: 'Users', userTypes: [USER_TYPE.NOTI], permissions: ['Notisphere_ManageUsers'] } ),
    noti_FAQ: route.register( '/notisphere/faqs', 'noti_FAQ', noti_FAQ, { ...META_AUTH_REQUIRED, breadCrumb: 'FAQs', userTypes: [USER_TYPE.NOTI], permissions: ['Notisphere_ManageFAQs'] } ),
    noti_EmailTemplate: route.register( '/notisphere/email-templates', 'noti_EmailTemplate', noti_EmailTemplate, { ...META_AUTH_REQUIRED, breadCrumb: 'Email Templates', userTypes: [USER_TYPE.NOTI], permissions: ['Notisphere_ManageEmailTemplates'] } ),
    noti_ContentManagement: route.register( '/notisphere/content-management', 'noti_ContentManagement', noti_ContentManagement, { ...META_AUTH_REQUIRED, breadCrumb: 'Content Management', userTypes: [USER_TYPE.NOTI], permissions: ['Notisphere_ManageEmailTemplates'] } ),
    noti_Location: route.register( '/notisphere/locations', 'noti_Location', noti_Location, { ...META_AUTH_REQUIRED, breadCrumb: 'Locations', userTypes: [USER_TYPE.NOTI], permissions: ['Notisphere_ManageLocations'] } ),
    noti_Known_Provider: route.register( '/notisphere/location-pre-processor/known-providers', 'noti_Known_Provider', noti_Known_Provider, { ...META_AUTH_REQUIRED, breadCrumb: 'Known Providers', userTypes: [USER_TYPE.NOTI], permissions: ['Notisphere_ManageLocations'] } ),
    noti_Location_preProcessor: route.register( '/notisphere/location-pre-processor', 'noti_Location_preProcessor', noti_Location_preProcessor, { ...META_AUTH_REQUIRED, breadCrumb: 'Location Pre-Processor', userTypes: [USER_TYPE.NOTI], permissions: ['Notisphere_ManageLocations'] } ),
    noti_LocationPreProcessor_ItemDetail: route.register( '/notisphere/location-pre-processor/new', 'noti_LocationPreProcessor_ItemDetail', noti_LocationPreProcessor_ItemDetail, { ...META_AUTH_REQUIRED, breadCrumb: 'New', userTypes: [USER_TYPE.NOTI], permissions: ['Notisphere_ManageLocations'] } ),
    noti_Location_preProcessorView: route.register( '/notisphere/location-pre-processor/view/:id', 'noti_Location_preProcessorView', noti_Location_preProcessorView, { ...META_AUTH_REQUIRED, breadCrumb: 'View', userTypes: [USER_TYPE.NOTI], permissions: ['Notisphere_ManageLocations'] } ),
    noti_LocationPreProcessor_EditItem: route.register( '/notisphere/location-pre-processor/edit/:id', 'noti_LocationPreProcessor_EditItem', noti_LocationPreProcessor_ItemDetail, { ...META_AUTH_REQUIRED, breadCrumb: 'Edit', userTypes: [USER_TYPE.NOTI], permissions: ['Notisphere_ManageLocations'] } ),
    noti_EmailTemplate_EditItem: route.register( '/notisphere/email-templates/edit/:id', 'noti_EmailTemplate_Edit', noti_EmailTemplate_Edit, { ...META_AUTH_REQUIRED, breadCrumb: 'Edit', userTypes: [USER_TYPE.NOTI], permissions: ['Notisphere_ManageEmailTemplates'] } ),
    noti_LocationVerification: route.register( '/notisphere/locations-verification', 'noti_LocationVerification', noti_LocationVerification, { ...META_AUTH_REQUIRED, breadCrumb: 'Locations Verification', userTypes: [USER_TYPE.NOTI], permissions: ['Notisphere_ManageLocations'] } ),
    noti_QuickLinks: route.register( '/notisphere/quick-links', 'noti_QuickLinks', noti_QuickLinks, { ...META_AUTH_REQUIRED, breadCrumb: 'Quick Links', userTypes: [USER_TYPE.NOTI] } ),
    noti_LookupValues: route.register( '/notisphere/lookup-values', 'noti_LookupValues', noti_LookupValues, { ...META_AUTH_REQUIRED, breadCrumb: 'Lookup Values', userTypes: [USER_TYPE.NOTI], permissions: ['Notisphere_ManageLookupValues'] } ),
    noti_ProductField: route.register( '/notisphere/fields', 'noti_ProductField', noti_ProductField, { ...META_AUTH_REQUIRED, breadCrumb: 'Product Fields', userTypes: [USER_TYPE.NOTI], permissions: ['Notisphere_ManageProductFields'] } ),
    noti_manage_user_guides: route.register( '/notisphere/manage-user-guide', 'noti_manage_user_guides', noti_UserGuide, { ...META_AUTH_REQUIRED, breadCrumb: 'Manage User Guides', userTypes: [USER_TYPE.NOTI], permissions: ['Notisphere_ManageUserGuides'] } ),
    noti_manage_supplier_master: route.register( '/notisphere/manage-supplier-list', 'noti_manage_user_guides', noti_SupplierList, { ...META_AUTH_REQUIRED, breadCrumb: 'Manage Supplier Master', userTypes: [USER_TYPE.NOTI], permissions: ['Notisphere_ManageUserGuides'] } ),
    noti_open_fda: route.register( '/notisphere/open-fda', 'noti_open_fda', noti_OpenFda, { ...META_AUTH_REQUIRED, breadCrumb: 'FDA', userTypes: [USER_TYPE.NOTI], permissions: ['Notisphere_ViewFDAInformation'] } ),
    //for provider
    prov_Dashboard: route.register( '/provider/dashboard', 'prov_Dashboard', prov_Dashboard, { ...META_AUTH_REQUIRED, breadCrumb: 'Dashboard', userTypes: [USER_TYPE.PROV] } ),
    prov_Recall: route.register( '/provider/recalls', 'prov_Recall', prov_Recall, { ...META_AUTH_REQUIRED, breadCrumb: 'Recalls', userTypes: [USER_TYPE.PROV], permissions: ['Provider_ManageRecalls'] } ),
    er_Responses: route.register( '/provider/er-response', 'er_Responses', er_Responses, { ...META_AUTH_REQUIRED, breadCrumb: 'eR Responses', userTypes: [USER_TYPE.PROV], permissions: ['Provider_ResponderRole'] } ),
    prov_RecallView: route.register( '/provider/recalls/view/:id/:providerCommunicationId', 'prov_RecallView', prov_RecallView, { ...META_AUTH_REQUIRED, breadCrumb: 'View', userTypes: [USER_TYPE.PROV], permissions: ['Provider_ManageRecalls'] } ),
    prov_DisruptionView: route.register( '/provider/product-disruption/view/:id/:providerCommunicationId', 'prov_DisruptionView', prov_DisruptionView, { ...META_AUTH_REQUIRED, breadCrumb: 'View', userTypes: [USER_TYPE.PROV], permissions: ['Provider_ManageDisruption'] } ),

    prov_ProdDisruption: route.register( '/provider/product-disruption', 'prov_ProdDisruption', prov_ProdDisruption, { ...META_AUTH_REQUIRED, breadCrumb: 'Supply Advisories', userTypes: [USER_TYPE.PROV], permissions: ['Provider_ManageDisruption'] } ),
    prov_ProductDisruption_NewItem: route.register( '/provider/product-disruption/new', 'prov_Disruption_NewItem', prov_ProductDisruption_ItemDetail, { ...META_AUTH_REQUIRED, breadCrumb: 'New', userTypes: [USER_TYPE.PROV], permissions: ['Provider_ManageDisruption'] } ),
    prov_ProductDisruption_EditItem: route.register( '/provider/product-disruption/edit/:id', 'prov_Disruption_EditItem', prov_ProductDisruption_ItemDetail, { ...META_AUTH_REQUIRED, breadCrumb: 'Edit', userTypes: [USER_TYPE.PROV] , permissions: ['Provider_ManageDisruption']} ),
    prov_ProductDisruption_ViewItem: route.register( '/provider/product-disruption/view/:id', 'prov_Disruption_ViewItem', prov_ProductDisruption_ViewItem, { ...META_AUTH_REQUIRED, breadCrumb: 'View', userTypes: [USER_TYPE.PROV], permissions: ['Provider_ManageDisruption'] } ),
    prov_ProductDisruption_AccessDenied_ViewItem: route.register( '/provider/product-disruption/accessdenied/:id/:providerCommunicationId', 'prov_Disruption_AccessDenied_ViewItem', prov_ProductDisruption_AccessDenied_ViewItem, { auth: false, userTypes: [USER_TYPE.PROV], permissions: ['Provider_ResponderRole'] } ),

    prov_Role: route.register( '/provider/roles', 'prov_Role', Role, { ...META_AUTH_REQUIRED, breadCrumb: 'Roles', userTypes: [USER_TYPE.PROV], permissions: ['Provider_ManageRoles'] } ),
    prov_User: route.register( '/provider/users', 'prov_User', User, { ...META_AUTH_REQUIRED, breadCrumb: 'Users', userTypes: [USER_TYPE.PROV], permissions: ['Provider_ManageUsers'] } ),
    prov_LocationVerification: route.register( '/provider/locations-verification', 'prov_LocationVerification', prov_LocationVerification, { ...META_AUTH_REQUIRED, breadCrumb: 'Locations Verification', userTypes: [USER_TYPE.PROV], permissions: ['Provider_ManageLocations'] } ),
    prov_Site: route.register( '/provider/sites', 'prov_Site', prov_Site, { ...META_AUTH_REQUIRED, breadCrumb: 'Sites', userTypes: [USER_TYPE.PROV], permissions: ['Provider_ManageLocations'] } ),
    prov_Internal_Actions: route.register( '/provider/template-actions', 'prov_Internal_Actions', prov_Internal_Actions, { ...META_AUTH_REQUIRED, breadCrumb: 'Template Actions', userTypes: [USER_TYPE.PROV], permissions: ['Provider_ManageRecalls'] } ),
    prov_Organization: route.register( '/provider/organization', 'prov_Organization', prov_Organization, { ...META_AUTH_REQUIRED, breadCrumb: 'General', userTypes: [USER_TYPE.PROV], permissions: ['Provider_ManageProfile'] } ),
    prov_Distribution_List: route.register( '/provider/distribution-list', 'prov_Distribution_List', prov_Distribution_List, { ...META_AUTH_REQUIRED, breadCrumb: 'Distribution Lists', userTypes: [USER_TYPE.PROV], permissions: ['Provider_ManageRecalls'] } ),
    prov_SupplierContacts_List: route.register( '/provider/supplier-contacts', 'prov_SupplierContacts_List', prov_SupplierContacts_List, { ...META_AUTH_REQUIRED, breadCrumb: 'Supplier Contacts', userTypes: [USER_TYPE.PROV], permissions: ['Provider_ManageSupplierContacts'] } ),

    prov_Recall_NewItem: route.register( '/provider/recalls/new', 'prov_Recall_NewItem', prov_Recall_ItemDetail, { ...META_AUTH_REQUIRED, breadCrumb: 'New', userTypes: [USER_TYPE.PROV], permissions: ['Provider_ManageRecalls'] } ),
    prov_Recall_EditItem: route.register( '/provider/recalls/edit/:id', 'prov_Recall_EditItem', prov_Recall_ItemDetail, { ...META_AUTH_REQUIRED, breadCrumb: 'Edit', userTypes: [USER_TYPE.PROV], permissions: ['Provider_ManageRecalls'] } ),
    prov_EmailTemplate: route.register( '/provider/email-templates', 'prov_EmailTemplate', prov_EmailTemplate, { ...META_AUTH_REQUIRED, breadCrumb: 'Email Templates', userTypes: [USER_TYPE.PROV], permissions: ['Provider_ManageEmailTemplates'] } ),
    prov_EmailTemplate_EditItem: route.register( '/provider/email-templates/edit/:id', 'prov_EmailTemplate_Edit', prov_EmailTemplate_Edit, { ...META_AUTH_REQUIRED, breadCrumb: 'Edit', userTypes: [USER_TYPE.PROV], permissions: ['Provider_ManageEmailTemplates'] } ),
    delegateWebform: route.register( '/delegate-webform', 'delegate-webform', DelegateWebform, { auth: false, layout: 'layout-custom' } ),
    supplierDelegateWebform: route.register( '/supplier-delegate-webform', 'supplier-delegate-webform', SupplierDelegateWebform, { auth: false, layout: 'layout-custom' } ),
    recallNoticeWebform: route.register( '/provider/recall-notice-webform', 'recall-notice-webform', recallNoticeWebform, { auth: false, layout: 'layout-custom' } ),
    recallNoticeConfirmation: route.register( '/provider/recall-notice-webform/confirmation/:organizationId/:communicationId/:providerCommunicationId/:communicationType', 'confirmation', recallNoticeConfirmation, { auth: false, layout: 'layout-custom' } ),
    disruptionNoticeWebform: route.register( '/provider/disruption-notice-webform', 'disruption-notice-webform', disruptionNoticeWebform, { auth: false, layout: 'layout-custom' } ),
    disruptionNoticeConfirmation: route.register( '/provider/disruption-notice-webform/confirmation/:organizationId/:communicationId/:providerCommunicationId/:communicationType', 'disruptionConfirmation', disruptionNoticeConfirmation, { auth: false, layout: 'layout-custom' } ),
    //for supplier
    supp_Dashboard: route.register( '/supplier/dashboard', 'supp_Dashboard', supp_Dashboard, { ...META_AUTH_REQUIRED, breadCrumb: 'Dashboard', userTypes: [USER_TYPE.SUPP] } ),
    supp_Recall: route.register( '/supplier/recalls', 'supp_Recall', supp_Recall, { ...META_AUTH_REQUIRED, breadCrumb: 'Recalls', userTypes: [USER_TYPE.SUPP], permissions: ['Supplier_ManageRecalls'] } ),
    supp_Recall_NewItem: route.register( '/supplier/recalls/new', 'supp_Recall_NewItem', supp_Recall_ItemDetail, { ...META_AUTH_REQUIRED, breadCrumb: 'New', userTypes: [USER_TYPE.SUPP], permissions: ['Supplier_ManageRecalls'] } ),
    supp_ProdDisruption: route.register( '/supplier/product-disruption', 'supp_ProdDisruption', supp_ProdDisruption, { ...META_AUTH_REQUIRED, breadCrumb: 'Supply Advisories', userTypes: [USER_TYPE.SUPP], permissions: ['Supplier_ManageDisruption'] } ),
    supp_ProductDisruption_NewItem: route.register( '/supplier/product-disruption/new', 'supp_Disruption_NewItem', supp_ProductDisruption_ItemDetail, { ...META_AUTH_REQUIRED, breadCrumb: 'New', userTypes: [USER_TYPE.SUPP], permissions: ['Supplier_ManageDisruption'] } ),
    supp_ProductDisruption_EditItem: route.register( '/supplier/product-disruption/edit/:id', 'supp_Disruption_EditItem', supp_ProductDisruption_ItemDetail, { ...META_AUTH_REQUIRED, breadCrumb: 'Edit', userTypes: [USER_TYPE.SUPP] , permissions: ['Supplier_ManageDisruption']} ),
    supp_ProductDisruption_ViewItem: route.register( '/supplier/product-disruption/view/:id', 'supp_Disruption_ViewItem', supp_ProductDisruption_ViewItem, { ...META_AUTH_REQUIRED, breadCrumb: 'View', userTypes: [USER_TYPE.SUPP], permissions: ['Supplier_ManageDisruption'] } ),
    supp_Recall_EditItem: route.register( '/supplier/recalls/edit/:id', 'supp_Recall_EditItem', supp_Recall_ItemDetail, { ...META_AUTH_REQUIRED, breadCrumb: 'Edit', userTypes: [USER_TYPE.SUPP], permissions: ['Supplier_ManageRecalls'] } ),
    supp_Recall_ViewItem: route.register( '/supplier/recalls/view/:id', 'supp_Recall_ViewItem', supp_Recall_ViewItem, { ...META_AUTH_REQUIRED, breadCrumb: 'View', userTypes: [USER_TYPE.SUPP], permissions: ['Supplier_ManageRecalls'] } ),
    supp_Role: route.register( '/supplier/roles', 'supp_Role', Role, { ...META_AUTH_REQUIRED, breadCrumb: 'Roles', userTypes: [USER_TYPE.SUPP], permissions: ['Supplier_ManageRoles'] } ),
    supp_User: route.register( '/supplier/users', 'supp_User', User, { ...META_AUTH_REQUIRED, breadCrumb: 'Users', userTypes: [USER_TYPE.SUPP], permissions: ['Supplier_ManageUsers'] } ),
    supp_Sales_Rep: route.register( '/supplier/sales-rep', 'supp_SalesRep', supp_SalesRep, { ...META_AUTH_REQUIRED, breadCrumb: 'Sales Reps', userTypes: [USER_TYPE.SUPP], permissions: ['Supplier_ManageSalesRepresentative'] } ),
    supp_SalesRepNoticeWebform: route.register( '/supplier/sales-rep/disruption-webform', 'salesRepNoticeWebform', salesRepNoticeWebform, { auth: false, layout: 'layout-custom' } ),
    supp_Organization: route.register( '/supplier/organization', 'supp_Organization', supp_Organization, { ...META_AUTH_REQUIRED, breadCrumb: 'General', userTypes: [USER_TYPE.SUPP], permissions: ['Supplier_ManageProfile'] } ),
    supp_Provider: route.register( '/supplier/providers', 'supp_Provider', supp_Provider, { ...META_AUTH_REQUIRED, breadCrumb: 'Providers', userTypes: [USER_TYPE.SUPP], permissions: ['Supplier_ManageLocations'] } ),
    supp_Location: route.register( '/supplier/locations', 'supp_Location', supp_Location, { ...META_AUTH_REQUIRED, breadCrumb: 'Locations', userTypes: [USER_TYPE.SUPP], permissions: ['Supplier_ManageLocations'] } ),
    supp_LocationFiles: route.register( '/supplier/location-files', 'supp_LocationFiles', supp_LocationFiles, { ...META_AUTH_REQUIRED, breadCrumb: 'Files', userTypes: [USER_TYPE.SUPP], permissions: ['Supplier_ManageLocations'] } ),
    supp_Internal_Actions: route.register( '/supplier/recall-template-actions', 'supp_Internal_Actions', supp_Internal_Actions, { ...META_AUTH_REQUIRED, breadCrumb: 'Recall Template Actions', userTypes: [USER_TYPE.SUPP], permissions: ['Supplier_ManageRecalls'] }, ({ eventType: 'RECALL'}) ),
    supp_Internal_Actions_Pd: route.register( '/supplier/product-disruption-template-actions', 'supp_Internal_Actions_Pd', supp_Internal_Actions_Pd, { ...META_AUTH_REQUIRED, breadCrumb: 'Supply Advisory Template Actions', userTypes: [USER_TYPE.SUPP], permissions: ['Supplier_ManageDisruption'] }, ({ eventType: 'PRODUCT DISRUPTION'})),
    supp_Distribution_List: route.register( '/supplier/recall-distribution-list', 'supp_Distribution_List', supp_Distribution_List, { ...META_AUTH_REQUIRED, breadCrumb: 'Recall Distribution Lists', userTypes: [USER_TYPE.SUPP], permissions: ['Supplier_ManageRecalls'] } ),
    supp_Distribution_Lists_Pd: route.register( '/supplier/product-disruption-distribution-lists', 'supp_Distribution_Lists_Pd', supp_Distribution_Lists_Pd, { ...META_AUTH_REQUIRED, breadCrumb: 'Supply Advisory Distribution Lists', userTypes: [USER_TYPE.SUPP], permissions: ['Supplier_ManageDisruption'] },({ eventType: 'PRODUCT DISRUPTION'}) ),
}

export default paths