import {http} from '../../utils/http'
import conf from '../../config'

export const API_ROOT = `${conf.API_URL}`
const recallNoteService =
{
    saveCommunicationNote(communicationId,communicationType,providerCommunicationId,payload) {
        return http.post(`${API_ROOT}/provider/communication-notes/save-note/${communicationId}/${communicationType}/${providerCommunicationId}`, payload).then(resp => {
            return resp
        })
    },
    getCommunicationNote(recallNoteId) {
        return http.get(`${API_ROOT}/provider/communication-notes/get-note/${recallNoteId}`).then(resp => {
            return resp
        })
    },
    findCommunicationNote(communicationId,providerCommunicationId) {
        return http.get(`${API_ROOT}/provider/communication-notes/${communicationId}/${providerCommunicationId}`).then(resp => {
            return resp
        })
    },
    getDocumentById(docId) {
        return http.get(`${API_ROOT}/provider/communication-notes/document/${docId}`).then(resp => {
            return resp
        })
    },

}
export default recallNoteService