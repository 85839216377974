import { VueEditor } from 'vue2-editor'

export default {
    components: { VueEditor },
    props: {
        content: { type: String, default: ''},
        widthlg: { type: String, default: '71px'},
        label: { type: String, default: ''},
        placeholder: { type: String, default: ''},
        rules: { type: String, default: ''},
        validatorName: { type: String, default: ''},
        disabled: { type: Boolean, default: false},
        isRequired: { type: Boolean, default: false},
        hideDetails: { type: Boolean, default: false},
        hideToolBar: { type: Boolean, default: false},
        actioneditor: { type: Boolean, default: false},
        actionDisableEditor: { type: Boolean, default: false},
    },
    data(){
        return {
            customToolbar: [
                [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
                ['blockquote', 'code-block'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
                [{ 'script': 'sub'}, { 'script': 'super' }],
                [{ 'indent': '-1'}, { 'indent': '+1' }],
            ],
            customDisableToolbar: [
                []
            ],
            customActionToolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
                ['blockquote', 'code-block'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
                [{ 'indent': '-1'}, { 'indent': '+1' }],
            ],
            editorOptions: {
                modules: {
                    toolbar: true,
                },
            },
            editorOptionsnew: {
                modules: {
                    toolbar: false,
                },
            }
        }
    },
    mounted() {
    },
    created() {
        if(this.hideToolBar)
        {
            this.editorOptions.modules.toolbar=false
        }

    },
    computed: {
        disabledValidate() {
            return !this.rules || this.rules == '' ? true : false
        },
        editorContent: {
            get() { return this.content },
            set(updatedContent) { this.$emit('update:content', updatedContent) }
        },
    },
    watch: {
    },
    methods: {
        clickLabel() {
            this.$refs.theInput.focus()
        },

        fieldFocus() {
            this.addFloatLabel()
            this.addFocus()
        },
        fieldBlur() {
            if(!this._value){
                this.removeFloatLabel()
            }
            this.removeFocus()
        },
        checkFloatLabel(val) {
            if(!val && !this.isFocus){
                this.removeFloatLabel()
            } else {
                this.addFloatLabel()
            }
        },
        fieldBlr(){
            this.$emit('blur')
        },
        addFloatLabel() {
            this.$refs.wrapper.$el.classList.add('float-label')
            setTimeout(()=> {
                if(this.$refs.lbl != undefined)
                {
                    this.fsWidth = this.$refs.lbl.clientWidth
                }

            }, 250)
            this.floatLabel = true
        },
        removeFloatLabel() {
            this.$refs.wrapper.$el.classList.remove('float-label')
            this.floatLabel = false
        },
        addFocus() {
            this.$refs.wrapper.$el.classList.add('focus')
            this.isFocus = true
        },
        removeFocus() {
            this.$refs.wrapper.$el.classList.remove('focus')
            this.isFocus = false
        },
        getClassIndicate(validationContext){
            if(validationContext.validated || validationContext.dirty){
                var IsVal = this._getValidationState(validationContext) ? 'valid' : 'fail'
                return IsVal
            } return ''
        }
    }
}