<template>
  <b-card no-body class="news-card overflow-hidden">
    <b-row no-gutters class="wrapper">
      <b-col md="4" class="img" :style="{backgroundImage: 'url('+img+')'}">
      </b-col>
      <b-col md="8">
        <b-card-body>
          <b-card-title>
            <div v-html="title"></div>
          </b-card-title>
          <b-card-text>
            <slot name="description"/>
          </b-card-text>
          <b-button href="#" variant="primary" @click="readMore">{{$t('Lang.read_more')}}</b-button>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
    props: {
        title: {type: String, default: ''},
        img: {type: String, default: ''},
        id: {type: Number, default: 0}
    },
    data() {
        return {
        }
    },
    methods: {
        readMore(){
            this.$emit('readMore', this.id)
        }
    }
}
</script>

<style lang="scss" scoped>
  .news-card{
    margin-bottom: 15px;
    .wrapper{
      min-height: 190px;
    }
    .img{
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 150px;
    }
  }
</style>