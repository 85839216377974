import { getPermissions, getUserType, getOrgId ,getRoleNameType,getAllPermissionsCount} from '../misc/auth'

export default {
    permissions: state => state.permissions || getPermissions(),
    userType: state => state.userType || getUserType(),
    orgId: state => state.orgId || getOrgId(),
    roleName: state=>state.roleName||getRoleNameType(),
    AllPermissions: state=>state.AllPermissions || getAllPermissionsCount(),
    getUser: state=>state.user
}
