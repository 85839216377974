import recallServices from '../../../../services/provider/recall'
import FileViewModel from '../FileViewModel'
import delegateService from '../../../../services/provider/delegates'
export default {
    props: {
        type: { type: String, default: '' },
        value: {
            type: Object,
            default: {
                valueAcknowledge: null,
                valueText: null,
                valueNumeric: null,
                valueYesNo: null,
                valueCheckbox: null,
                valueImage: null,
                valueDocument: null,
                valueMultiChoice: [],
            },
        },
        actionValues: [],
        recallsite: [],
        communicationId: { type: String, default: null },
        paperResponseId: { type: Number, default: null },
        providerRecallId: { type: Number, default: 0 },
        recallActionId: { type: Number, default: null },
        editMode: { type: Boolean, default: false },
        isSupplier: { type: Boolean, default: false },
        isProvider: { type: Boolean, default: false },
        isDelegate: { type: Boolean, default: false },
        org_Id: { type: Number, default: null },
        delegateId: { type: Number, default: null },

    },
    data() {
        return {
            filesUpload: [],
            actualfilesUpload: [],
        }
    },
    components: {
        FileViewModel,
    },
    computed: {
        _value: {
            get() {
                let numValue = 0
                switch (this.type) {
                case 'Acknowledge':
                    return this.value && this.value.valueAcknowledge === true
                        ? true
                        : this.value && this.value.valueAcknowledge === false
                            ? false
                            : null
                case 'Text':
                    return this.value && this.value.valueText
                        ? this.value.valueText
                        : null
                case 'MultipleChoice':
                    return this.value && this.value.length > 0 ? this.value : null
                case 'Numeric':
                    numValue = this.value && Number.isInteger(this.value.valueNumeric) ? this.value.valueNumeric : null
                    if (numValue != null && numValue == 0) {
                        return String(numValue)
                    } else {
                        return this.value && this.value.valueNumeric
                            ? this.value.valueNumeric
                            : null
                    }
                case 'YesNo':
                    return this.value && this.value.valueYesNo === true
                        ? true
                        : this.value && this.value.valueYesNo === false
                            ? false
                            : null
                case 'Checkbox':
                    return this.value && this.value.valueCheckbox === true
                        ? true
                        : this.value && this.value.valueCheckbox === false
                            ? false
                            : null
                }
                return null
            },
            set(val) {
                switch (this.type) {
                case 'Text':
                    this.$emit('input', { valueText: val })
                    break
                case 'Numeric':
                    this.$emit('input', { valueNumeric: val })
                    break
                case 'YesNo':
                    this.$emit('input', { valueYesNo: val })
                    break
                case 'Checkbox':
                    this.$emit('input', { valueCheckbox: val })
                    break
                }
            },
        },
    },
    methods: {
        isChange() {
            this.$emit('isChange', true)
        },
        async fileInput(files) {

            for (let i = 0; i < files.length; i++) {
                const file = files[i]
                await this.uploadRecallActionFile(file)
            }
        },
        async uploadRecallActionFile(file) {
            if (file.size == 0) {
                this._showToast('Documented failed to upload. Please try again.', { variant: 'danger' })
            }
            else{
                this.actualfilesUpload.push(file)
                if (!this.editMode) {
                    this.filesUpload.push({
                        file: file,
                        name: file.name,
                        type: file.type,
                        size: file.size,
                        extension: file.name.split('.').pop(),
                        actionId: this.recallActionId,
                        paperresponseid: this.paperResponseId
                    })
                    this.filesUpload.push({
                        file: file,
                        name: file.name,
                        type: file.type,
                        size: file.size,
                        extension: '',
                        actionId: this.recallActionId,
                        paperresponseid: this.paperResponseId
                    })
                    //actualfilesUpload
                    if (this.type == 'Image') {
                        this.$emit('input', { valueImage: this.filesUpload })
                    }
                    if (this.type == 'Document') {
                        this.$emit('input', { valueDocument: this.filesUpload })
                    }
                }
                else {
                    if (this.type == 'Document') {
                        if (this.value.valueDocument == undefined) {
                            this.filesUpload.push({
                                file: file,
                                name: file.name,
                                type: file.type,
                                size: file.size,
                                extension: '',
                                actionId: this.recallActionId,
                                paperresponseid: this.paperResponseId,
                                id: 0
                            })
                            if (this.type == 'Document') {
                                this.$emit('input', { valueDocument: this.filesUpload })
                            }
                        }
                        else {
                            for (let i = 0; i < this.value.valueDocument.length; i++) {
                                this.filesUpload.push({
                                    name: this.value.valueDocument[i].name,
                                    type: this.value.valueDocument[i].type,
                                    size: this.value.valueDocument[i].size,
                                    extension: this.value.valueDocument[i].extension,
                                    actionId: this.recallActionId,
                                    paperresponseid: this.paperResponseId,
                                    id: this.value.valueDocument[i].id
                                })
                            }
                            this.filesUpload.push({
                                file: file,
                                name: file.name,
                                type: file.type,
                                size: file.size,
                                extension: '',
                                actionId: this.recallActionId,
                                paperresponseid: this.paperResponseId,
                                id: 0
                            })
                            if (this.type == 'Document') {
                                this.$emit('input', { valueDocument: this.filesUpload })
                            }
                        }
                    }
                    else {
                        if (this.value.valueImage == undefined) {
                            this.filesUpload.push({
                                file: file,
                                name: file.name,
                                type: file.type,
                                size: file.size,
                                extension: '',
                                actionId: this.recallActionId,
                                paperresponseid: this.paperResponseId,
                                id: 0
                            })
                            if (this.type == 'Image') {
                                this.$emit('input', { valueImage: this.filesUpload })
                            }
                        }
                        else{
                            for (let i = 0; i < this.value.valueImage.length; i++) {
                                this.filesUpload.push({
                                    name: this.value.valueImage[i].name,
                                    type: this.value.valueImage[i].type,
                                    size: this.value.valueImage[i].size,
                                    extension: this.value.valueImage[i].extension,
                                    actionId: this.recallActionId,
                                    paperresponseid: this.paperResponseId,
                                    id: this.value.valueImage[i].id
                                })
                            }
                            this.filesUpload.push({
                                file: file,
                                name: file.name,
                                type: file.type,
                                size: file.size,
                                extension: '',
                                actionId: this.recallActionId,
                                paperresponseid: this.paperResponseId,
                                id: 0
                            })
                            if (this.type == 'Image') {
                                this.$emit('input', { valueImage: this.filesUpload })
                            }
                        }
                    }
                }
            }

        },
        async removeDocument(index) {

            if (this.type == 'Image') {
                this.value.valueImage.splice(index, 1)
            }
            else if (this.type == 'Document') {
                this.value.valueDocument.splice(index, 1)
            }
        },
        deleteRecallActionFileAsync(fileId) {

            if (this.type == 'Document') {
                for (let i = 0; i < this.value.valueDocument.length; i++) {
                    if (this.value.valueDocument[i].id != undefined && this.value.valueDocument[i].id != fileId) {
                        this.filesUpload.push({
                            name: this.value.valueDocument[i].name,
                            type: this.value.valueDocument[i].type,
                            size: this.value.valueDocument[i].size,
                            extension: this.value.valueDocument[i].extension,
                            actionId: this.recallActionId,
                            paperresponseid: this.paperResponseId,
                            id: this.value.valueDocument[i].id
                        })
                    }

                }
                if (this.type == 'Document') {
                    this.$emit('input', { valueDocument: this.filesUpload })
                }
            }
            else {
                for (let i = 0; i < this.value.valueImage.length; i++) {
                    if (this.value.valueImage[i].id != undefined && this.value.valueImage[i].id != fileId) {
                        this.filesUpload.push({
                            name: this.value.valueImage[i].name,
                            type: this.value.valueImage[i].type,
                            size: this.value.valueImage[i].size,
                            extension: this.value.valueImage[i].extension,
                            actionId: this.recallActionId,
                            paperresponseid: this.paperResponseId,
                            id: this.value.valueImage[i].id
                        })
                    }
                }
                if (this.type == 'Image') {
                    this.$emit('input', { valueImage: this.filesUpload })
                }
            }
        },
        getRecallActionFilesAsync() {
            if (this.isDelegate) {
                delegateService
                    .getRecallActionFilesAsync(
                        this.communicationId,
                        this.recallActionId,
                        this.type,
                        this.org_Id,
                        this.delegateId
                    )
                    .then((resp) => {
                        if (!resp.error) {
                            if (this.type == 'Image') {
                                this.$emit('input', { valueImage: resp.data.d })
                            }
                            if (this.type == 'Document') {
                                this.$emit('input', { valueDocument: resp.data.d })
                            }
                        }
                    })
            } else {
                recallServices
                    .getRecallActionFilesAsync(
                        this.communicationId,
                        this.recallActionId,
                        this.type,
                        this.providerRecallId
                    )
                    .then((resp) => {
                        if (!resp.error) {
                            if (this.type == 'Image') {
                                this.$emit('input', { valueImage: resp.data.d })
                            }
                            if (this.type == 'Document') {
                                this.$emit('input', { valueDocument: resp.data.d })
                            }
                        }
                    })
            }
        },
    },
}
