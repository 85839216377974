import generic from '../misc/generic'

export const USER_TYPE = {
    NOTI: 'NOTISPHERE',
    PROV: 'PROVIDER',
    SUPP: 'SUPPLIER',
}

export const EMAIL_STATUS = {
    DRAFT: 'DRAFT',
    DELIVERY: 'DELIVERY',
    SENT: 'SENT',
    BOUNCE: 'Bounce',
    ERROR: 'ERROR'
}
export const USERS_STATUS = {
    VERIFIED: 'VERIFIED',
    VERIFICATION: 'VERIFICATION',
    NONVERIFIED: 'NONVERIFIED',
    DEACTIVATED: 'DEACTIVATED',
    LOCKED: 'LOCKED',
    PENDING: 'PENDING',
    SSO: 'SSO'
}
export const ACTION_TYPE = {
    INSERT: 'INSERT',
    UPDATE: 'UPDATE',
    RESPOND: 'RESPOND',
    VIEW: 'VIEW',
    EDIT: 'EDIT',
    DELETED: 'DELETED',
    NA: 'NA'
}
export const THRESHOLD_COLOR_STATUS = [
    { value: 'RED', text: 'danger', color: '#dc3545'},
    { value: 'GREEN', text: 'success', color: '#28a745'},
    { value: 'YELLOW', text: 'warning', color: '#ffc107'}
]

export const TYPICAL_STATUS = [
    { value: 'ACTIVE', text: 'Active', color: '#28a745' },
    { value: 'INACTIVE', text: 'Deactivated', color: '#dc3545' },
    { value: 'DELETED', text: 'Deleted', color: '#dc3545' }
]

export const SITE_STATUS = [
    { value: 'ACTIVE', text: 'Active', color: '#28a745' },
    { value: 'INACTIVE', text: 'Deactivated', color: '#dc3545' },
    { value: 'PENDING', text: 'PENDING', color: '#ffc107' },
]

export const USER_STATUS = [
    { value: 'VERIFIED', text: 'Verified', color: '#28a745' },
    { value: 'VERIFICATION', text: 'Verification Email Sent', color: '#ffc107' },
    { value: 'NONVERIFIED', text: 'Non-Verified', color: '#28a745' },
    { value: 'DEACTIVATED', text: 'Deactivated', color: '#dc3545' },
    { value: 'LOCKED', text: 'Locked', color: '#dc3545' },
    { value: 'PENDING', text: 'Pending', color: '#ffc107' },
    { value: 'SSO', text: 'SSO', color: '#28a745'}
]
export const EMAIL_ACTIVITY = [
    { value: 'Opened', text: 'Opened', color: '#28a745' },
    { value: 'NA', text: 'NA', color: '#ffc107' },
    { value: 'Error', text: 'Error', color: '#DC3545' },
    { value: 'Sent', text: 'Sent', color: '#28a745' },
    { value: 'Delivered', text: 'Delivered', color: '#28a745' },
    { value: 'Click', text: 'Opened', color: '#28a745' }
]

export const ORGANIZATION_STATUS = [
    { value: 'VERIFIED', text: 'Verified', color: '#28a745' },
    { value: 'DEACTIVATED', text: 'Deactivated', color: '#dc3545' },
    { value: 'PENDING', text: 'Pending', color: '#ffc107' }
]
export const SUPLLIERLIST_STATUS = [
    { value: 'ACTIVE', text: 'Active', color: '#28a745' },
    { value: 'DEACTIVATED', text: 'Deactivated', color: '#dc3545' },
    { value: 'PENDING', text: 'Pending', color: '#ffc107' },
    { value: 'LINKED', text: 'Linked', color: '#ffA500' }
]
export const SUPLLIER_EMAIL_STATUS = [
    { value: 'Opened', text: 'Opened', color: '#28a745' },
    { value: 'NA', text: 'NA', color: '#ffc107' },
    { value: 'Error', text: 'Error', color: '#DC3545' },
    { value: 'Sent', text: 'Sent', color: '#28a745' },
    { value: 'Delivered', text: 'Delivered', color: '#28a745' },
    { value: 'Click', text: 'Click', color: '#28a745' }
]
export const RECALL_STATUS = [
    { value: 'DRAFT', text: 'Draft', color: '#ffc107' },
    { value: 'OPEN', text: 'Opened', color: '#28a745' },
    { value: 'DELIVERY', text: 'Delivered', color: '#28a745' },
    { value: 'DELIVERED', text: 'Delivered', color: '#28a745' },
    { value: 'CLOSED', text: 'Closed', color: '#009efb' },
    { value: 'ERROR', text: 'Error', color: '#DC3545' },
    { value: 'Bounce', text: 'Error', color: '#DC3545' },
    { value: 'SENT', text: 'Sent', color: '#28a745' },
    { value: 'SENDING', text: 'Sending', color: '#28a745' },
    { value: 'ERROR_MISSING_TEMPLATE', text: 'Sent', color: '#28a745' },
    { value: 'ERROR_SENDING', text: 'Sent', color: '#28a745' },
    { value: 'PENDING', text: 'Sent', color: '#28a745' }
]
export const PROVIDER_EVENTLISTING_STATUS = [
    { value: 'DRAFT', text: 'Draft', color: '#ffc107' },
    { value: 'OPEN', text: 'Open', color: '#28a745' },
    { value: 'DELIVERED', text: 'Delivered', color: '#28a745' },
    { value: 'CLOSED', text: 'Closed', color: '#009efb' },
    { value: 'COMPLETED', text: 'Completed', color: '#009efb' },
    { value: 'ERROR', text: 'Error', color: '#DC3545' },
    { value: 'SENT', text: 'Sent', color: '#28a745' },
    { value: 'SENDING', text: 'Sending', color: '#28a745' },
    { value: 'ERROR_MISSING_TEMPLATE', text: 'Sent', color: '#28a745' },
    { value: 'ERROR_SENDING', text: 'Sent', color: '#28a745' },
    { value: 'PENDING', text: 'Sent', color: '#28a745' }
]
export const PROVIDER_EVENT_STATUS = [
    { value: 'DRAFT', text: 'Draft', color: '#ffc107' },
    { value: 'CLOSED', text: 'Closed', color: '#009efb' },
    { value: 'OPEN', text: 'Open', color: '#28a745' },
    { value: 'COMPLETED', text: 'Completed', color: '#009efb' },
]
export const PROVIDER_RECALL_STATUS = [
    { value: 'DRAFT', text: 'Draft', color: '#ffc107' },
    { value: 'OPEN', text: 'Open', color: '#28a745' },
    { value: 'CLOSED', text: 'Closed', color: '#009efb' },
]
export const SUPPLIER_RECALL_STATUS = [
    { value: 'DRAFT', text: 'Draft', color: '#ffc107' },
    { value: 'OPEN', text: 'Open', color: '#28a745' },
    { value: 'CLOSED', text: 'Closed', color: '#009efb' },

]
export const PROVIDER_DASHBOARD_STATUS = [
    { value: 'ALL', text: 'All'},
    { value: 'Draft', text: 'Draft'},
    { value: 'Open', text: 'In Progress / Open'},
    { value: 'Completed', text: 'Completed'},
]
export const PROVIDER_DASHBOARD_TYPE = [
    { value: 'ALL', text: 'All'},
    { value: 'PRODUCT DISRUPTION', text: 'Supply Advisories'},
    { value: 'RECALL', text: 'Recalls'}
]
export const NOTI_RECALL_STATUS = [
    { value: 'DRAFT', text: 'Draft', color: '#ffc107' },
    { value: 'OPEN', text: 'Open', color: '#28a745' },
    { value: 'CLOSED', text: 'Closed', color: '#009efb' },
    { value: 'ERROR', text: 'Error', color: '#DC3545' },
    { value: 'SENT', text: 'Sent', color: '#28a745' },
    { value: 'OPEN', text: 'Opened', color: '#28a745' },
    { value: 'DELIVERY', text: 'Delivered', color: '#28a745' },
    { value: 'Delivery', text: 'Delivered', color: '#28a745' },
    { value: 'DELIVERED', text: 'Delivered', color: '#28a745' },
    { value: 'CLOSED', text: 'Closed', color: '#009efb' },
    { value: 'ERROR', text: 'Error', color: '#DC3545' },
    { value: 'BOUNCE', text: 'Error', color: '#DC3545' },
    { value: 'SENT', text: 'Sent', color: '#28a745' },
    { value: 'SENDING', text: 'Sending', color: '#28a745' },
    { value: 'ERROR_MISSING_TEMPLATE', text: 'Sent', color: '#28a745' },
    { value: 'ERROR_SENDING', text: 'Sent', color: '#28a745' },
    { value: 'PENDING', text: 'Sent', color: '#28a745' }
]
export const SALES_REP_STATUS = [
    { value: 'ERROR', text: 'Error', color: '#DC3545' },
    { value: 'Bounce', text: 'Error', color: '#DC3545' },
    { value: 'Complaint', text: 'Error', color: '#DC3545' },
    { value: 'Sent', text: 'Sent', color: '#28a745' },
    { value: 'Send', text: 'Sent', color: '#28a745' },
    { value: 'Open', text: 'Opened', color: '#28a745' },
    { value: 'Click', text: 'Click', color: '#28a745' },
    { value: 'DELIVERY', text: 'Delivered', color: '#28a745' },
    { value: 'Delivery', text: 'Delivered', color: '#28a745' },
    { value: 'Delivered', text: 'Delivered', color: '#28a745' },
    { value: 'DELIVERED', text: 'Delivered', color: '#28a745' },
    { value: 'BOUNCE', text: 'Error', color: '#DC3545' }
]

export const ER_RECALL_STATUS = [
    { value: 'DRAFT', text: 'Draft', color: '#ffc107' },
    { value: 'ERROR', text: 'Error', color: '#DC3545' },
    { value: 'SENT', text: 'Sent', color: '#28a745' }
]

export const RECALL_STATUS_PROVIDER = [
    { value: 'OPEN', text: 'In Progress', color: '#28a745' },
    { value: 'NOTCOMPLETED', text: 'In Progress', color: '#28a745' },
    { value: 'COMPLETED', text: 'Completed', color: '#007bff' }
]
export const LOCATION_PRE_PROCESSOR_STATUS = [
    { value: 'PENDING', text: 'Pending', color: '#ffc107' },
    { value: 'IN PROGRESS', text: 'In Progress', color: '#28a745' },
    { value: 'COMPLETED', text: 'Completed', color: 'rgb(0, 158, 251)' },
    { value: 'ERROR', text: 'Error', color: '#DC3545' },
    { value: 'DEACTIVATED', text: 'Deactivated', color: '#dc3545' },
]
export const AFFECTED_PRODUCT_STATUS = [
    { value: 'DESTROYED', text: 'Destroyed'},
    { value: 'NONE', text: 'None' },
    { value: 'RETURNED', text: 'Returned' }
]

export const RECALL_CLASSIFICATIONS = [
    {value: 'CLASSI', text: 'Class I'},
    {value: 'CLASSII', text: 'Class II'},
    {value: 'CLASSIII', text: 'Class III'},
    {value: 'NOT_YET_CLASSIFIED', text: 'Not Yet Classified'}
]

export const ANTICIPATED_DURATION = [
    {value: 1, text: '< 2 weeks'},
    {value: 2, text: '2-4 weeks'},
    {value: 3, text: '> month'},
    {value: 4, text: '> quarter'},
]
export const RECALL_TYPE = [
    {value: 'MARKETEVENT', text: 'Market Event'},
    {value: 'DISCONTINUATION', text: 'Discontinuation'},
    {value: 'Removal', text: 'Removal'},
    {value: 'GENERALINFORMATION', text: 'General Information'},
    {value: 'SHORTAGE', text: 'SHORTAGE'},
    {value: 'BACKORDER', text: 'BACKORDER'},
    {value: 'ALLOCATION', text: 'ALLOCATION'},
    {value: 'Product Advisory', text: 'Product Advisory'},
    {value: 'Field Safety Notice', text: 'Field Safety Notice'},
    {value: 'Correction', text: 'Correction'},
    {value: 'PRODUCTORKITCHANGE', text: 'Product or Kit change'}
]

export const RECALL_TYPE_DASHBOARD_CARD = [
    {value: 'MARKETEVENT', text: 'Market Event'},
    {value: 'DISCONTINUATION', text: 'Discont’n'},
    {value: 'Removal', text: 'Removal'},
    {value: 'GENERALINFORMATION', text: 'Gen Info'},
    {value: 'SHORTAGE', text: 'Shortage'},
    {value: 'BACKORDER', text: 'Backorder'},
    {value: 'ALLOCATION', text: 'Allocation'},
    {value: 'Product Advisory', text: 'Product Advisory'},
    {value: 'Field Safety Notice', text: 'Field Safety Notice'},
    {value: 'Correction', text: 'Correction'},
    {value: 'PRODUCTORKITCHANGE', text: 'Prod/Kit Chg'}
]

export const RECALL_TYPEOFCHANGE = [
    {value: 'Recall details', text: 'Recall details'},
    {value: 'Products', text: 'Products'},
    {value: 'Actions', text: 'Actions'},
    {value: 'Affected locations', text: 'Affected locations'},
    {value: 'Other', text: 'Other'}
]
export const DISRUPTION_TYPEOFCHANGE = [
    {value: 'Disruption details', text: 'Supply advisory details'},
    {value: 'Products', text: 'Products'},
    {value: 'Actions', text: 'Actions'},
    {value: 'Affected customers', text: 'Affected customers'},
    {value: 'Other', text: 'Other'}
]

export const PRODUCT_FIELD_DATA_TYPE = [
    {value: 'Text', text: 'Text' },
    {value: 'Number', text: 'Number' },
    {value: 'Date', text: 'Date' },
    {value: 'ListSingle', text: 'List Single' },
    {value: 'ListMultiple', text: 'List Multiple' },
    {value: 'YesNo', text: 'Yes/No' },
    {value: 'TrueFalse', text: 'True/False' }
]

export const PRODUCT_FIELD_DATA_TYPE_OPTIONS_DATE = [
    {value: 'CURRENT_TIME', text: 'Current  Date/Time'},
    {value: 'NOT_APPLY', text: 'Blank'}
]
export const COMMUNICATION_TYPE = {
    RECALL: 'RECALL',
    PRODUCTDISRUPTION: 'PRODUCT DISRUPTION'
}
export const PROVIDERREPORTS = [
    {value: 'Affected Locations', text: 'Affected Locations'},
    {value: 'Affected Products', text: 'Affected Products'},
    {value: 'Change History', text: 'Change History'},
    {value: 'Customized Content', text: 'Customized Content Report'},
    {value: 'Delegate Status', text: 'Delegate Status'}
]
export const SUPPLIERREPORTS = [
    {value: 'Change History', text: 'Change History'},
    {value: 'Delegate Status', text: 'Delegate Status'},
    {value: 'FDA Effectiveness Report', text: 'FDA Effectiveness Report'},
    {value: 'Notification Report', text: 'Notification Report'},
    {value: 'Provider Status', text: 'Provider Status'},
    {value: 'Recall Event Status by Location', text: 'Recall Event Status by Location'}
]
export const PRODUCT_DISRUPTION_TYPES = {
    BACKORDER: 'BACKORDER',
    SHORTAGE: 'SHORTAGE',
    DISCONTINUATION: 'DISCONTINUATION',
    MARKETEVENT: 'MARKETEVENT',
    GENERALINFORMATION: 'GENERALINFORMATION',
    ALLOCATION: 'ALLOCATION',
    PRODUCTORKITCHANGE: 'PRODUCTORKITCHANGE'
}
export const FILEPREVIEW = {
    URL: 'https://view.officeapps.live.com/op/embed.aspx?src=',
    SPREADSHEET: 'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    DOCUMENT: 'vnd.openxmlformats-officedocument.wordprocessingml.document',
    PPT: 'vnd.openxmlformats-officedocument.presentationml.presentation'
}
export const COMMUNICATION_TYPE_LIST = [
    {value: 'RECALL', text: 'Recall'},
    {value: 'PRODUCT DISRUPTION', text: 'Supply Advisory'}
]
export const PRODUCT_DISRUPTION_TYPE = [
    {value: 'BACKORDER', text: 'Backorder'},
    {value: 'SHORTAGE', text: 'Shortage'},
    {value: 'DISCONTINUATION', text: 'Discontinuation'},
    {value: 'MARKETEVENT', text: 'Market Event'},
    {value: 'GENERALINFORMATION', text: 'General Information'},
    {value: 'ALLOCATION', text: 'Allocation'},
    {value: 'PRODUCTORKITCHANGE', text: 'Product or Kit change'}
]
export const PROVIDER_COMMUNICATION_TYPE = [
    {value: 'BACKORDER', text: 'Backorder'},
    {value: 'SHORTAGE', text: 'Shortage'},
    {value: 'DISCONTINUATION', text: 'Discontinuation'},
    {value: 'MARKETEVENT', text: 'Market Event'},
    {value: 'GENERALINFORMATION', text: 'General Information'},
    {value: 'Removal', text: 'Removal'},
    {value: 'Product Advisory', text: 'Product Advisory'},
    {value: 'Correction', text: 'Correction'},
    {value: 'Field Safety Notice', text: 'Field Safety Notice'}
]
export const PRODUCT_DISRUPTION_EDIT_TYPE = [
    {value: 'BACKORDER', text: 'Backorder'},
    {value: 'SHORTAGE', text: 'Shortage'},
    {value: 'DISCONTINUATION', text: 'Discontinuation'},
    {value: 'ALLOCATION', text: 'Allocation'},
    {value: 'PRODUCTORKITCHANGE', text: 'Product or Kit change'}
]
export const PRODUCT_DISRUPTION_EDIT_WITH_MARKET_GENERAL_INFO_EVENT_TYPE = [
    {value: 'MARKETEVENT', text: 'Market Event'},
    {value: 'GENERALINFORMATION', text: 'General Information'}
]
export const ALLOCATIONPROCESS = [
    {value: 'YES', text: 'Yes'},
    {value: 'NO', text: 'No'},
]
export const PRODUCT_DISRUPTION_REASON = [
    {value: 'FORCE_DE_MAJEURE', text: 'Force de Majeure'},
    {value: 'PLANT_CLOSURE', text: 'Plant closure'},
    {value: 'RAW_MATERIAL_SHORTAGE', text: 'Raw material shortage'},
    {value: 'SKU_RATIONALIZATION', text: 'SKU rationalization'},
    {value: 'QUALITY_ISSUE', text: 'Quality issue'}
]
export const ACTION_TYPE_LIST = [
    {
        value: 'YesNo',
        text: 'Yes/No',
        icon: '<i class="fas fa-american-sign-language-interpreting"></i>'
    },
    {
        value: 'Text',
        text: 'Text',
        icon: '<i class="fas fa-align-right"></i>'
    },
    {
        value: 'Image',
        text: 'Image',
        icon: '<i class="far fa-images"></i>'
    },
    {
        value: 'Document',
        text: 'Document',
        icon: '<i class="far fa-file-alt"></i>'
    },
    {
        value: 'Checkbox',
        text: 'Checkbox',
        icon: '<i class="far fa-check-square"></i>'
    },
    {
        value: 'Numeric',
        text: 'Numeric',
        icon: '<i class="fas fa-sort-numeric-down"></i>'
    },
    {
        value: 'MultipleChoice',
        text: 'Multiple Choice',
        icon: '<i class="fas fa-tasks"></i>'
    }
]

export const ENUM_PRODUCT_FIELD_DATA_TYPE = {
    TEXT: 'Text',
    NUMBER: 'Number',
    DATE: 'Date',
    LIST_SINGLE: 'ListSingle',
    LIST_MULTIPLE: 'ListMultiple',
    YES_NO: 'YesNo',
    TRUE_FALSE: 'TrueFalse'
}

export const ENUM_DATE_TYPE_DEFAULT_VALUE = {
    CURRENT_TIME: 'CURRENT_TIME',
    NOT_APPLY: 'NOT_APPLY'
}
export const ALLOWEDEXTENSIONS =[ 'pdf','docx','xlsx','mp4']
export const ALLOWEDIMAGEEXTENSIONS=['png','jpg']
export const SOURCEFILTER=['Paper','Electronic']
export const CLASSIFICATIONFILTER=['Class I','Class II','Class III','Not Yet Classified']
export const ANTICIPATEDDURATIONFILTER = [
    { display: 'Blank', value: ' ' },
    { display: '< 2 weeks', value: '< 2 weeks' },
    { display: '2-4 weeks', value: '2-4 weeks' },
    { display: '> month', value: '> month' },
    { display: '> quarter', value: '> quarter' }
]
export const PROVIDERDISRUPTIOSTATUSFILTER=['Draft','Open','Completed','Closed']
export const DISABLEDPERMISSIONS=['Provider_ManageEmailTemplates','Provider_ManageLocations','Provider_ManageRoles','Provider_ManageSupplierContacts','Provider_ManageUsers','Provider_ManageProfile']
export class Product {
    constructor(data) {
        this.id = data && data.id || null
        this.description = data && data.description || ''
        this.fields = data && data.fields || [],
        this.order = data && data.order || 1
    }
}

export const COLOR_CODES = [
    {
        id: 0, //red
        code: '#ef5350'
    },
    {
        id: 1, // purple
        code: '#ea80fc'
    },
    {
        id: 2, //blue
        code: '#42a5f5'
    },
    {
        id: 3, //teal
        code: '#26a69a'
    },
    {
        id: 4, //yelllow
        code: '#ffeb3b'
    },
    {
        id: 5, //orange
        code: '#ff9800'
    },
    {
        id: 6, //brown
        code: '#bcaaa4'
    },
    {
        id: 7, //grey
        code: '#9e9e9e'
    },
    {
        id: 8, //blue grey
        code: '#90a4ae'
    },
    {
        id: 9, //cyan
        code: '#26c6da'
    }
]

export const AVATAR_COLOR_CODES = [
    {
        id: 0,
        code: 'dark'
    },
    {
        id: 1,
        code: 'secondary'
    },
    {
        id: 2,
        code: 'success'
    },
    {
        id: 3,
        code: 'warning'
    },
    {
        id: 4,
        code: 'danger'
    },
    {
        id: 5,
        code: 'primary'
    }
]

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000'

export const SAVE_FILTER_CONNECTIONS = [
    {
        SavedProviderConnections: {
            Page_Recalls: {
                supplierOrgName: null,
                recallName: null,
                siteName: null,
                category: null
            }
        }
        ,SavedSupplierConnections: {
            Page_Recalls: {
                status: null
            },
            Page_Recalls_AffectedLocations: {
                status: null
            }
        },
        SavedNotisphereConnections: {
            Page_Recalls: {
                status: null,
                supplierOrgId: null
            },
            Page_LocationPreProcessor: {
                status: null,
                supplierName: null
            }
        },
        user_id: null
    }]
export const OUTPUT_DATE_FORMAT = 'MM/DD/YYYY'
export const OUTPUT_SHORTDATE_FORMAT = 'MM/DD/YYYY HH:mm'
