/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import _ from 'lodash'
import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import appStore from '../store'
import route from '../misc/route'
import { APP_MUTATIONS, AUTH_MUTATIONS } from '../store/mutations'
import { APP_ACTIONS, AUTH_ACTIONS } from '../store/actions'
import { hasAnyPermission, getAccessToken, getRefreshToken, removeToken } from '../misc/auth'
import { paths } from './paths'
import { USER_TYPE } from './../misc/commons'
import { cosh } from 'core-js/core/number'
const LOGIN_PATH = paths.login.path
const ACCESS_DENIED_PATH = paths.prov_ProductDisruption_AccessDenied_ViewItem.path
const NOTFOUND_PATH = paths.notFound.path

Vue.use(Router)

export function getDefaultRoute() {
    if(localStorage.getItem('access') == 'denied')
    {
        return ACCESS_DENIED_PATH.replace(':id',localStorage.getItem('id')).replace(':providerCommunicationId',localStorage.getItem('providerCommunicationId'))
    }
    else
    {
        if(localStorage.getItem('access') == 'denied')
        {
            return ACCESS_DENIED_PATH.replace(':id',localStorage.getItem('id')).replace(':providerCommunicationId',localStorage.getItem('providerCommunicationId'))
        }
        else
        {
            let path = ''
            switch (appStore.state.userType) {
            case USER_TYPE.NOTI:
                path = paths.noti_Dashboard.path
                break
            case USER_TYPE.PROV:
                if (appStore.state.permissions && (appStore.state.permissions.includes('View_Dashboard') || appStore.state.permissions.includes('Provider_ViewDashboard'))) {
                    path = paths.prov_Dashboard.path
                }
                else if (appStore.state.permissions && (appStore.state.permissions.includes('Provider_ManageRecalls')) && (!appStore.state.permissions.includes('View_Dashboard') || !appStore.state.permissions.includes('Provider_ViewDashboard')))
                {
                    path = paths.prov_Recall.path
                }
                else if (appStore.state.permissions && (appStore.state.permissions.includes('Provider_ManageDisruption')) && (!appStore.state.permissions.includes('View_Dashboard') || !appStore.state.permissions.includes('Provider_ViewDashboard')))
                {
                    path = paths.prov_ProdDisruption.path
                }
                else {
                    path = localStorage.getItem('access') == 'denied' ? ACCESS_DENIED_PATH.replace(':id', localStorage.getItem('id')).replace(':providerCommunicationId', localStorage.getItem('providerCommunicationId')) : paths.prov_Dashboard.path
                }
                break
            case USER_TYPE.SUPP:
                path = paths.supp_Dashboard.path
                break
            default:
                path = NOTFOUND_PATH
                break
            }
            console.log(appStore.state.roleName)
            if(appStore.state.roleName ==='Responder')
            {
                console.log(localStorage.getItem('access'))
                return path= localStorage.getItem('access') == 'denied' ? ACCESS_DENIED_PATH.replace(':id',localStorage.getItem('id')).replace(':providerCommunicationId',localStorage.getItem('providerCommunicationId')): paths.er_Responses.path
            }
            else if(appStore.state.roleName ==='Recall Coordinator'){
                if(appStore.state.userType===USER_TYPE.PROV)
                    return path=paths.prov_Recall.path
                else
                    return path=paths.supp_Recall.path
            }
            else if(appStore.state.roleName ==='Sourcing Coordinator'){
                if(appStore.state.userType===USER_TYPE.PROV)
                    return path=paths.prov_Dashboard.path
                else
                    return path=paths.supp_Dashboard.path
            }
            else
                return path
        }
    }
}

// Create a new router
let vueRouter = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: getDefaultRoute()
        },
        ...route.buildConfig(paths),
        {
            path: '**',
            redirect: paths.notFound.path
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
        if (to.hash) {
            return { selector: to.hash }
        }
        return { x: 0, y: 0 }
    }
})
const checkPermission = (to, next) => {
    if (appStore.state.token) {
        if (to.path === LOGIN_PATH) {
            if (appStore.state.user === '') {
                appStore.dispatch(AUTH_ACTIONS.GET_USER_PROFILE)
            }
            next({
                path:
          appStore.state && appStore.state.lastedRouteNotAuth
              ? appStore.state.lastedRouteNotAuth.path
              : getDefaultRoute()
            })
        }
        if (to.meta && to.meta.permissions && to.meta.permissions.length > 0) {
            let id = to.params.id
            let providerCommunicationId = to.params.providerCommunicationId
            if (!hasAnyPermission(to.meta.permissions)) {
                if(localStorage.getItem('access') != 'sent')
                {
                    localStorage.setItem('access','denied')
                    localStorage.setItem('id',id)
                    localStorage.setItem('providerCommunicationId',providerCommunicationId)
                    appStore.commit(APP_MUTATIONS.PERMISSION_DENIED, { isDenied: true, errorMessage: null, id, providerCommunicationId })
                }
                next(getDefaultRoute())
            } else {
                next()
            }
        } else {
            next()
        }
    } else {
        let redirect = appStore.state.lastedRouteNotAuth || vueRouter.currentRoute
        if (to.meta.auth == false) {
            next()
        } else if (to.path !== LOGIN_PATH) {
            vueRouter.push({
                path: LOGIN_PATH,
                query: {
                    redirect: redirect.fullPath || redirect.path
                }
            }).catch(err => {})
        } else {
            next()
        }
    }
}
export function forceRedirect(){
    vueRouter.push({path: getDefaultRoute()}).catch(error => {

    })
}
vueRouter.beforeEach((to, from, next) => {
    appStore.commit(APP_MUTATIONS.LOADING_START)
    if (!getAccessToken() && !getRefreshToken()) {
        if(to.path != '/' &&  to.path != '/login' && ((to.query.conId && to.query.conId > 0 )|| (to.query.tabName && to.query.tabName!='')|| (to.query.logtype && to.query.logtype!='') || (to.query.nonAck && to.query.nonAck!='') || (to.query.pageName && to.query.pageName!='')))
        {
            appStore.dispatch(APP_MUTATIONS.LASTED_ROUTE_NOT_AUTH,to)
        }
        removeToken()
        if (to.meta.auth) {
            vueRouter.push({
                path: LOGIN_PATH
            }).catch(err => {})
        } else {
            if (to.matched.length && to.path != '/') {
                next()
            } else
            if (!to.matched.length || to.path == '/') {
                vueRouter.push({
                    path: LOGIN_PATH
                }).catch(err => {})
            }
        }
    } else {
        if (!to.matched.length && to.meta.auth) {
            next(getDefaultRoute())
        } else
            checkPermission(to, next)
    }
})

vueRouter.afterEach((to, from) => {
    appStore.commit(APP_MUTATIONS.LOADING_END)
    appStore.commit(APP_MUTATIONS.CHANGED_ROUTER, to)
    appStore.dispatch(APP_ACTIONS.SET_PREVIOUS_ROUTE, from)
})

vueRouter.onReady(() => {
    appStore.subscribe((mutations, store) => {
        switch (mutations.type) {
        case AUTH_MUTATIONS.AUTH_SUCCESS: {
            let redirect = store.lastedRouteNotAuth || vueRouter.currentRoute //usually, this is login path
            if (!redirect.meta.auth) {
                let redirectTo = ''
                if (!_.isEmpty(redirect.query)) {
                    if (redirect.query.redirect) {
                        if (redirect.query.redirect === '/') {
                            redirectTo = getDefaultRoute()
                        } else {
                            redirectTo = redirect.query.redirect
                        }
                    } else {
                        redirectTo = getDefaultRoute()
                    }
                } else {
                    redirectTo = getDefaultRoute()
                }
            }
            break
        }
        case AUTH_MUTATIONS.AUTH_ERROR: {
            let redirect = store.lastedRouteNotAuth || vueRouter.currentRoute
            if (redirect.meta.auth && redirect.path !== LOGIN_PATH) {
                if (['NotFound'].includes(redirect.name))
                    vueRouter.push({
                        path: LOGIN_PATH
                    }).catch(err => {})
                else
                    vueRouter.push({
                        path: LOGIN_PATH,
                        query: {
                            redirect: redirect.fullPath || redirect.path
                        }
                    }).catch(err => {})
            } else if (vueRouter.currentRoute.path !== LOGIN_PATH){
                vueRouter.push({
                    path: LOGIN_PATH
                }).catch(err => {})
            }
            break
        }
        }
    })
    appStore.dispatch(AUTH_ACTIONS.GET_USER_PROFILE).then(() => {
        vueRouter.beforeEach((to, from, next) => {
            next()
        })
        appStore.commit(APP_MUTATIONS.INIT_APP_FINISHED)
    })
})

Vue.use(Meta)

export default vueRouter
